import { Component, OnInit, Input } from '@angular/core';
import { RelationshipService } from '../../../service/auth-service/relationship.service';
import { FamilyService } from '../../../service/auth-service/family.service';
import { ToastrService } from 'ngx-toastr';
import { FamilylistJson } from 'app/member/member.modal';

@Component({
	selector: 'ms-publications',
	templateUrl: './publications.component.html',
	styleUrls: ['./publications.component.scss']
})
export class PublicationsComponent implements OnInit {

	@Input() publicationArray: any;
	@Input() myId: any;
	emptyFamilyMembers: boolean;
	familyDetailsList: any;
	getRelationShipList = [];

	constructor(private familyService: FamilyService, private relationshipService: RelationshipService, private toastr: ToastrService) { }

	ngOnInit() {
		this.getFamilyMemberDetails();
		this.getRelationShipDetails();
	}

	getFamilyMemberDetails() {
		this.familyService.getFamilybyIdView(this.myId,true).subscribe(
			list => {
				if (list) {
					const deserialize = new FamilylistJson().deserialize(list);
					
					this.publicationArray = deserialize.members.filter((data) => {
						if (data.familyHead === false) {
							return data
						}
					})
					if (this.publicationArray.length === 0) {
						this.emptyFamilyMembers = true;
					} else {
						this.emptyFamilyMembers = false;
						this.familyDetailsList = this.publicationArray;
					}
				} else {
					this.emptyFamilyMembers = true;
				}
			}, err => {
				console.log('err', err)
				this.toastr.error('Something went wrong while getting the family')
			})
	}

	getRelationShipDetails() {
		this.relationshipService.getAllRelationshipList().subscribe(
			relation => {
				this.getRelationShipList = relation;
			}, err => {
				console.log('err', err)
				this.toastr.error('Something went wrong while getting the relationship')
			})
	}
}
