import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(private authService: AuthService, private http: HttpClient) { }
    public getFamilyMemberCount(): Observable<any> {
        return this.http.get<any>(this.authService._getFamilyMemberCount);
    }
    public memberLoginCount(): Observable<any> {
        return this.http.get<any>(this.authService._memberLoginCount);
    }
    public getRecentlyAddedMemberList(): Observable<any> {
        return this.http.get<any>(this.authService._getRecentlyAddedMember);
    }

    public getBirthdayMember(data) {
        return this.http.post<any>(this.authService._getBirthdayMembers, data);
    }

    public getBirthdayMemberByYear(data) {
        return this.http.post<any>(this.authService._getBirthdayMemberByYear, data);
    }

    public getWeddingMembers(data) {
        return this.http.post<any>(this.authService._getWeddingMembers, data);
    }

    public getWeeklySubscriptionAmount(data) {
        return this.http.post<any>(this.authService._getWeeklySubscriptionAmount, data);
    }

    public getCoverSummary() {
        return this.http.get<any>(this.authService._getCoverSummary);
    }

    public getVerses(): Observable<any> {
        return this.http.get(this.authService._getVerses + '?format=json&order=random')
    }

    public getMemberPrayerCount(data) {
        return this.http.post<any>(this.authService._getMemberPrayerCount, data);
    }

    public getMemberPrayertByDate(data) {
        return this.http.post<any>(this.authService._getMemberPrayertByDate, data);
    }

    public deletePrayerRequest(id) {
        return this.http.put(this.authService.deleteMemberPrayerRequest + '?id=' + id, '')
    }
    public topTenExpenses(data) {
        return this.http.post(this.authService._getTopTenExpenses, data);
    }
    public expenseVsIncome(data) {
        return this.http.post(this.authService._getExpenseVsIncome, data);
    }

}
