import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeAfterComma'
})
export class CapitalizeAfterCommaPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    
    // Split the address string by comma
    const segments = value.split(',');
    
    // Map over each segment to capitalize the first letter of each word
    const transformedSegments = segments.map(segment => {
      // Split the segment into words
      const words = segment.trim().split(' ');
      
      // Capitalize the first letter of each word and join back
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      
      return capitalizedWords.join(' ');
    });
    
    // Join the transformed segments back with a comma
    return transformedSegments.join(', ');
  }
}
