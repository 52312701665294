export class AnnouncementJsonList {
    deserialize(input: any) {

        const Obj = {
            churchId: input.churchId,
            announcement: input.announcement,
            announcementDescription: input.announcementDescription,
            fromDateTime: input.fromDateTime,
            toDateTime: input.toDateTime,
            attachment: input.attachment,
            fileSize: input.fileSize,
            active: input.active,
            id: input.id,
        }
        return Obj;
    }

}