import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EventsService {
    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllChurchEvents(active): Observable<any> {
        return this.http.get<any>(this.authService.getAllChurchEvents + '?active=' + active);
    }

    public getChurchEventsByDate(date): Observable<any> {
        return this.http.get<any>(this.authService.getChurchEventsByDate + '?eventDate=' + date);
    }

    public saveChurchEvent(data) {
        return this.http.post(this.authService.saveChurchEvent, data);
    }

    public updateChurchEvent(data) {
        return this.http.put(this.authService.updateChurchEvent, data);
    }

    public deleteChurchEvent(Id): Observable<any> {
        return this.http.delete(this.authService.deleteChurchEvent + '?id=' + Id);
    }

    public uploadEventAttachment(file): Observable<any> {
        return this.http.post(this.authService.uploadEventAttachment, file);
    }
    public uploadchurcheventimage(file): Observable<any> {
        return this.http.post(this.authService.uploadchurcheventimage, file);
    }

    public getAllEventTypes(data): Observable<any> {
        return this.http.post(this.authService.getAllEventTypes, data);
    }

}
