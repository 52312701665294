import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChurchConfigService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getMyChurchConfig() {
        return this.http.get<any>(this.authService._getMyChurchConfig);
    }

    public getChurchConfigById(id): Observable<any> {
      return this.http.get<any>(this.authService._getChurchConfigById + '?id=' + id)
    }

    public saveChurchConfig(data) {
        return this.http.post(this.authService._saveChurchConfig, data);
    }

    public updateChurchConfig(data) {
        return this.http.put(this.authService._updateChurchConfig, data)
    }

}