// import { Component, OnInit, Input } from '@angular/core';
// import { DashboardService } from 'app/service/auth-service/dashboard.service';
// import { ConvertDateTimeFromUTC, validateAllFormFields } from 'app/shared/utils/common-functions';

// @Component({
//   selector: 'app-ms-saas-dashboard-card',
//   templateUrl: './saas-dashboard-card.component.html',
//   styleUrls: ['./saas-dashboard-card.component.scss']
// })

// export class SaasDashboardCardComponent implements OnInit {


//   @Input() dashboardCards: any;
//   currentDate;
//   weekFirstday;
//   weekLastday;
//   formNo;

//   constructor(private dashboardService : DashboardService) { }

//   ngOnInit() {
//     this.currentDate = new Date;
//     var weekFirstday = new Date(this.currentDate.setDate(this.currentDate.getDate() - this.currentDate.getDay()));
//     var weekLastday = new Date(this.currentDate.setDate(this.currentDate.getDate() - this.currentDate.getDay() + 6));

//     this.weekFirstday = ConvertDateTimeFromUTC(weekFirstday, 'DD/MM/YYYY'),
//       this.weekLastday = ConvertDateTimeFromUTC(weekLastday, 'DD/MM/YYYY')

//       this.getFormNo();
//       this. memberLoginCount();
//       console.log(this.dashboardCards)
//   }

//   getFormNo()
//   {
  
//     this.dashboardService.getFamilyMemberCount().subscribe(
//       data => {
//         this.formNo = data.recentFormNumber
//       });
     
//   }
//   memberLoginCount()
//   {
//     this.dashboardService.memberLoginCount().subscribe(
//       data => {
//         console.log(data)
//         this.dashboardCards.push({
//           name: 'Mobile Users',
//           // first_label_name: 'Members',
//           // first_label_value: data.memberCount,
//           second_label_name: '',
//           second_label_value: data.memberLoginCount ,
//           color: 'light-bg',  // example color
//           icon: 'assets/default_user/Overal.svg',  
//           iconcolor: 'green-dim-bg', 
//           class: 'example-class',  // example class
//           label_text_color: 'text-blue'  // example text color class
//          });
//       });
      
  
//   }

// }

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DashboardService } from 'app/service/auth-service/dashboard.service';
import { ConvertDateTimeFromUTC, validateAllFormFields } from 'app/shared/utils/common-functions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ms-saas-dashboard-card',
  templateUrl: './saas-dashboard-card.component.html',
  styleUrls: ['./saas-dashboard-card.component.scss']
})
export class SaasDashboardCardComponent implements OnInit, OnDestroy {

  @Input() dashboardCards: any[];
  currentDate: Date;
  weekFirstday: string;
  weekLastday: string;
  formNo: string;
  private formNoSubscription: Subscription;
  private memberLoginCountSubscription: Subscription;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.currentDate = new Date();
    const weekFirstday = new Date(this.currentDate.setDate(this.currentDate.getDate() - this.currentDate.getDay()));
    const weekLastday = new Date(this.currentDate.setDate(this.currentDate.getDate() - this.currentDate.getDay() + 6));

    this.weekFirstday = ConvertDateTimeFromUTC(weekFirstday, 'DD/MM/YYYY');
    this.weekLastday = ConvertDateTimeFromUTC(weekLastday, 'DD/MM/YYYY');

    this.getFormNo();
    this.memberLoginCount();
  }

  ngOnDestroy() {
    // Unsubscribe from subscriptions to avoid memory leaks
    if (this.formNoSubscription) {
      this.formNoSubscription.unsubscribe();
    }
    if (this.memberLoginCountSubscription) {
      this.memberLoginCountSubscription.unsubscribe();
    }
  }

  getFormNo() {
    this.formNoSubscription = this.dashboardService.getFamilyMemberCount().subscribe(
      data => {
        this.formNo = data.recentFormNumber;
      });
  }

  memberLoginCount() {
    this.memberLoginCountSubscription = this.dashboardService.memberLoginCount().subscribe(
      data => {
        // Check if 'Mobile Users' card already exists
        const mobileUsersCardIndex = this.dashboardCards.findIndex(card => card.name === 'Mobile Users');

        if (mobileUsersCardIndex === -1) {
          this.dashboardCards.push({
            name: 'Mobile Users',
            second_label_value: data.memberLoginCount,
            color: 'light-bg',
            icon: 'assets/default_user/Overal.svg',
            
            iconcolor: 'purple-dim-bg',
            class: 'example-class',
            label_text_color: 'text-blue'
          });
        } else {
          // Update existing 'Mobile Users' card if it already exists
          this.dashboardCards[mobileUsersCardIndex].second_label_value = data.memberLoginCount;
        }
      });
  }

}
