import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CoverService {
  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllCoverList(active): Observable<any> {
    return this.http.get<any>(this.authService._getAllCovers + '?active=' + active);
  }

  public getCoverbyId(CoverId): Observable<any> {
    return this.http.get<any>(this.authService._getCoverById + '?id=' + CoverId);
  }

  public addCoverDetails(data) {
    return this.http.post(this.authService._addCover, data);
  }

  public updateCoverDetail(data) {
    return this.http.put(this.authService._updateCover, data);
  }

  public deleteCoverData(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteCover + '?id=' + deleteId);
  }

  public deactivateByCoverId(CoverId): Observable<any> {
    return this.http.put(this.authService._deactivateCover + '?id=' + CoverId, '')
  }

  public ActiveCoverById(Id) {
    return this.http.put(this.authService._activeCoverById + '?id=' + Id, '');
  }

  public getCoversbyChurchwithMemberdetails(status): Observable<any> {
    return this.http.get<any>(this.authService._getcoversbychurchwithmemberdetails + '?active=' + status);
  }

  
  //Cover History
  public getbycover(id): Observable<any> {
    return this.http.get<any>(this.authService._getbycover + '?coverId=' + id);
  }
  public getbymember(id): Observable<any> {
    return this.http.get<any>(this.authService._getbymember + '?memberId=' + id);
  }

}
