import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DashboardService } from 'app/service/auth-service/dashboard.service';
import { FamilyService } from 'app/service/auth-service/family.service';
import { validateAllFormFields } from 'app/shared/utils/common-functions';

@Component({
  selector: 'ms-add-family',
  templateUrl: './add-family.component.html',
  styleUrls: ['./add-family.component.scss']
})
export class AddFamilyComponent implements OnInit {

  addNewFamily: FormGroup;
  data: any;
  countriesList;
  isoCode;
  salutationArray = [
    'Mr',
    'Ms',
    'Mrs',
    'Miss',
    'Master',
    'Baby',
    'Dr',
    'Rev',
    'Pastor',
    'Bishop',
    'Rt. Rev',
    'Capt',
    'Col',
    'Lt. Col',
    'Maj',
    'Cdr',
    'Chief Justice',
    'Brig',
    'Gp. Capt',
    'Adv',
    'Sis',
    'Bro'
  ]
  constructor(private dashboardService: DashboardService, private familyService: FamilyService,
    private fb: FormBuilder, public dialogRef: MatDialogRef<AddFamilyComponent>) { }

  ngOnInit() {
    this.getCountryCodes();
    this.createForm();
    this.getFormNo();

  }
  getCountryCodes() {
    this.familyService
      .getCountryDetails<any>()
      .subscribe(
        jsondata => {
          this.countriesList = jsondata;
          this.addNewFamily
            .patchValue({
              countryCode: this.countriesList[97].dial_code
            })

        },
        errorResponse => {
          console.log('Error', errorResponse);
        });
  }
  selectedValue(event) {  

    const countryIsoCode = this.countriesList.forEach(country => {
      if ( event.source.triggerValue === country.name) {
        this.isoCode = country.code
      }
    })
  }
  createForm() {
    this.addNewFamily = this.fb.group({
      name: ['', [Validators.required]],
      salutation:['',[Validators.required]],
      mobileNumber: [''],
      gender:['', [Validators.required]],
      countryCode: [null],
      formno: [null, [Validators.required]]
    });
  }
  onSelectSalutation(form) {
    const salutation = form.value.salutation;
    switch (salutation) {
      case 'Dr': {
        break;
      }
      case 'Mr':
        {
          form.patchValue({
            gender: 'M',
            // maritalStatus: 'Married'
          })
         
          break;
        }
      case 'Master': {
        form.patchValue({
          gender: 'M',
          // maritalStatus: 'Single'
        })
        break;
      }
      case 'Baby':
        {
          form.patchValue({
            gender: 'F',
            // maritalStatus: 'Single'
          })
          break;
        }
      case 'Miss': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
        
        break;
      }
      case 'Mrs': {
        form.patchValue({
          gender: 'F',
          //  maritalStatus: 'Married'
        })
       
        
        break;
      }
      case 'Sis': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
        break;
      }
      case 'Bro': {
        form.patchValue({
          gender: 'M',
          // maritalStatus: 'Single'
          })
        
          break;
      }
      case 'Ms': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
       
        break;
      }
  }
}
  onAddFamily(form) {
    if( form.value.mobileNumber != "" &&  form.value.countryCode === ""){form.controls['countryCode'].setErrors({ 'incorrect': true })}
    // else if ((form.value.mobileNumber === null && form.value.mobileNumber === "") && (form.value.countryCode != null ||form.value.countryCode !="" )){form.controls['mobileNumber'].setErrors({ 'incorrect': true });}
    else{form.controls['countryCode'].setErrors(null);}
    if (form.valid) {
      const sendObj = {
        name:form.value.name,
        salutation: form.value.salutation,
        mobileNumber: form.value.mobileNumber,
        mobileCode:form.value.mobileNumber ? form.value.countryCode:null,
        mobileCodeISO:form.value.mobileNumber? this.isoCode:null,
        formno: form.value.formno,
        gender: form.value.gender
      }
      this.dialogRef.close(sendObj);
    } else {
      validateAllFormFields(form)
    }
  }
 
  getFormNo() {
    this.dashboardService.getFamilyMemberCount().subscribe(
      data => {
        this.addNewFamily.get("formno").setValue(data.recentFormNumber + 1);
      });

  }


}
