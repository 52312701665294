export class FamilyJson {
    deserialize(input: any) {
        const obj = {
            firstName: input.firstName,
            id: input.id,
            familyHead: input.familyHead,
            familyId: input.familyId
        }
        return obj
    }
}