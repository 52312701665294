import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ms-enable-user',
  templateUrl: './enable-user.component.html',
  styleUrls: ['./enable-user.component.scss']
})
export class EnableUserComponent implements OnInit {

  enableUserForm: FormGroup
  data: any;
  name;
  churchName;
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EnableUserComponent>) { }

  ngOnInit() {
    this.enableUserForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })

    const username = this.data.userName;
    var atPosition = username.indexOf('@');

    if (atPosition > 0) {
      this.name = username.slice(0, atPosition);
      this.churchName = username.slice(atPosition);
    } else {
      this.name = this.data.userName;
    }

    this.enableUserForm.patchValue({
      userName: this.name,
      password: this.data.password,
    })

  }

  onFormSubmit() {
    this.dialogRef.close(this.enableUserForm.value);
  }

}
