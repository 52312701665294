import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FamilyService } from 'app/service/auth-service/family.service';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { validateAllFormFields } from 'app/shared/utils/common-functions';
import { ToastrService } from 'ngx-toastr';
import { AddMemberComponent } from '../add-member/add-member.component';
import { addMemberJson } from '../add-member/add-member.modal';
import { MemberService } from 'app/service/auth-service/members.service';
import { MemberJSON } from 'app/member/member.modal';
import { I } from '@angular/cdk/keycodes';
@Component({
  selector: 'ms-member-popup',
  templateUrl: './member-popup.component.html',
  styleUrls: ['./member-popup.component.scss']
})
export class MemberPopupComponent implements OnInit {


  
  
    data: string;
    transferMemberForm: FormGroup;
    addMemberForm: FormGroup;
    memberDetails: any;
    usermanagelist = [];
    transferReason = [{
      name: "New Member",
    },
    {
      name: "Existing Member"
    }];
  
    selectMembers = [];
    newData = []
    instructions;
  show: boolean =true;

    constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddMemberComponent>, private route: ActivatedRoute,
      private memberService: MemberService, private familyService: FamilyService, private filterTextPipe: FilterTextPipe, private toastr: ToastrService) { }
  
  
    ngOnInit() {
  
      if (this.memberDetails && this.memberDetails.member ) {
      
        this.getMemberByMemberId(this.memberDetails.member.id);
    }
      this.transferMemberForm = this.fb.group({
        selectedReason: [this.transferReason[1], [Validators.required]],
        familyHeadName: ['', Validators.required],
        id: 0,
        spouseName: [''],
        image: ['']
  
      }); 
  
    }
    searchFamilyFilterForm(sendObj) {
  
      const columnSearch = {
        'offset': 0,
        'noOfRows': 10,
        'familySearch': this.transferMemberForm.value.familyHeadName.trim(),
        'columnName': 'Name',
        'active': true,
      }
      Object.assign(columnSearch, sendObj)
      this.familyService.searchFamilyByColums(columnSearch).subscribe(
        data => {
          if (data && data.model.length > 0) {
            const dataList = data.model.filter(item => item.id !== this.memberDetails.member.familyId)
            this.usermanagelist = dataList.map((list) => {
              return new addMemberJson().deserialize(list)
            });
          } else {
            this.usermanagelist = [];
  
            this.toastr.error('Please select the correct Family Head')
          }
        }, err => {
          console.log('err', err);
  
          this.usermanagelist = []
          let errorString = 'Something went wrong while searching family List';
  
          if (err && err.error && err.error.length > 0 && err.error[0] && err.error[0].errorMessage) {
            errorString = err.error[0].errorMessage;
          }
          this.toastr.error(errorString)
        });
    }
  
    onSelectingReason() {
      if (this.transferMemberForm.value.selectedReason.name === 'Existing Member') {
        this.transferMemberForm.get('selectedReason').setValidators([Validators.required]);
        this.transferMemberForm.get('familyHeadName').setValidators([Validators.required]);
  
      } else {
        this.transferMemberForm.get('familyHeadName').clearValidators();
        this.transferMemberForm.get('selectedReason').setValidators([Validators.required]);
  
      }
    }
  
    onselectMember(member) {
      this.transferMemberForm.patchValue({
       spouseName: member.firstName,
        id: member.id,
        image: member.image
      })
    }


    getMemberByMemberId(id) {
      this.memberService.getMemberByFamily(id).subscribe(data => {
        // console.log(data);
        
          this.selectMembers = data.map((list) => new MemberJSON().deserialize(list)).filter(obj => {
           
              if ((obj.id !== this.memberDetails.family.id) && (obj.gender != this.memberDetails.family.gender) && (obj.maritalStatus != true)) {
                  return obj;
              } 
             
          });
        
      })
    }

  
  
  
    onAddMember(form){
   
        let sendObj = {};
        if (this.transferMemberForm.value.selectedReason.name === 'Existing Member') {
          sendObj = {
            reason: form.value.selectedReason.name,
           spouseId: form.value.id,
           name: form.value.spouseName,
           image: form.value.image,
           
          }
        }
        else  if (this.transferMemberForm.value.selectedReason.name === 'New Member'){
          sendObj = {
            reason: form.value.selectedReason.name
           
          }
        }
        this.dialogRef.close(sendObj);
      
    }
  }
  


