import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LookUpModel } from 'app/member/add-family/lookupmodel';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {  

  maritalStatuses : LookUpModel[] = [ 
      {
        id: 1,
        name: "Single"      
      },
      {
        id: 2,
        name: "Married"      
      },
      {
        id: 3,
        name: "Sole survivor"      
      }
    ]

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllFamilyList(active): Observable<any> {
    return this.http.get<any>(this.authService._getAllFamilyList + '?active=' + active);
  }

  public getFamilybyId(familyId): Observable<any> {
    return this.http.get<any>(this.authService._getFamilybyId + '?familyId=' + familyId);
  }

  public getFamilybyIdView(familyId,active): Observable<any> {
    return this.http.get<any>(this.authService._getFamilybyId + '?familyId=' + familyId+'&active='+active);
  }

  public getFamilybyIdwithSpouse(familyId): Observable<any> {
    return this.http.get<any>(this.authService._getFamilybyIdWithSpouse + '?familyId=' + familyId);
  }

  public searchFamilyByColums(data) {
    return this.http.post<any>(this.authService._searchByFamily, data);
  }

  public addFamilyDetails(data) {
    return this.http.post(this.authService._addFamilyhDetail, data);
  }

  public getFamilyListByPaging(data, sortby) {
    return this.http.post<any>(this.authService._getFamilyListByPaging + sortby, data);
  }

  public updateFamilyDetail(data) {
    return this.http.put(this.authService._updateFamilyDetail, data);
  }

  public changeMaritalStatus(data) {
    return this.http.put(this.authService._changeMaritalStatus + '?memberId=' + data,'');
  }

  public deleteFamilyBYId(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteFamilyBYId + '?familyId=' + deleteId);
  }
  public deleteFamilyData(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteFamilyData + '?id=' + deleteId);
  }

  public deactivateByFamilyId(familyId): Observable<any> {
    return this.http.put(this.authService._deactivateByFamilyId + '?id=' + familyId, '')
  }

  public ActiveFamilyById(Id) {
    return this.http.put(this.authService._activeFamilyById + '?id=' + Id, '');
  }

  public ActivateEntireFamilyById(familyId) {
    return this.http.post(this.authService._activateEntireFamilyById + '?familyId=' + familyId,'');
  }
  public updateSpouse(familyId){
    return this.http.post(this.authService._updateSpouse + '?familyId=' + familyId,'');
  }

  public uploadFamily(file): Observable<any> {
    return this.http.post(this.authService._uploadFamily,
      file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }

  public uploadFamilyImage(file): Observable<any> {
    return this.http.post(this.authService._uploadFamilyImage,
      file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }

  public uploadTransferCer(file) {
    return this.http.post(this.authService._uploadtransfercertificate, file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }

  public uploadfamilytransfercertificate(file) {
    return this.http.post(this.authService._uploadfamilytransfercertificate, file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }

  public downloadAttachement(file) {
    return this.http.post(this.authService._downloadAttachement + '?link=' + file, '', { responseType: 'blob' });
  }

  public onFamilyHeadChange(sondObj) {
    return this.http.post(this.authService._onFamilyHeadChange, sondObj);
  }

  public onCheckAvailabilityOfFormNumber(formNumber) {
    return this.http.post(this.authService._onValidateFormNumber + '?formNumber=' + formNumber, '');
  }

  public getCountryDetails<T>(): Observable<T> {
    return this.http.get<T>(this.authService._getCountryCode);
  }
  public getAllHobbies(active): Observable<any> {
    return this.http.get<any>(this.authService._getAllHobbies + '?active=' + active)
  }

  public getAllMinistrialInterest(active): Observable<any> {
    return this.http.get<any>(this.authService._getAllMinistrialInterest + '?active=' + active)
  }
  public familyAndSpouseSave(sendObj): Observable<any> {
    return this.http.post(this.authService._familyAndSpouseSave, sendObj)
  }

  public addBaptistCertificate(sendObj): Observable<any> {
    return this.http.post(this.authService._addBaptistCertificate, sendObj)
  }
  public getMobileNumber(churchId,mobileNumber): Observable<any> {
    return this.http.get(this.authService._getMobileNumber + '?churchId=' + churchId+'&mobileNumber='+mobileNumber)
  }
  
  public getMaritalStatuses() : Observable<LookUpModel[]>{     
    return of(this.maritalStatuses);
} 

public getMaritalStatusNameById(id: number) : string {
   return this.maritalStatuses.find(x => x.id ==  id)["name"];
} 

public getMaritalStatusIdByName(name:string) :number {     
  return this.maritalStatuses.find(x => x.name ==  name).id;  
} 

public isMarriedByMaritalStatusName(name:string) :boolean {     
  return name == 'Married'? true : false;  
}
 
public isMarriedByMaritalStatusId(id: number) :boolean {
 return this.isMarriedByMaritalStatusName(this.getMaritalStatusNameById(id));
} 
}
