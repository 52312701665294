import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from '../service/auth-service/auth.service';
import { CoreService } from '../service/core/core.service';
import { DashboardService } from 'app/service/auth-service/dashboard.service';
import { routeAnimation } from 'app/core/route-animation/route.animation';
import { fadeInRightAnimation } from 'angular-animations';
import { AppConfig } from '../app-config';
import { MemberService } from 'app/service/auth-service/members.service';
import { ToastrService } from 'ngx-toastr';
import { data } from 'jquery';
import { AnnouncementService } from 'app/service/auth-service/announcement.service';
import { EventsService } from 'app/service/auth-service/event.service';
import { ConvertDateTimeFromUTC } from 'app/shared/utils/common-functions';
import { CalendarEvent } from 'angular-calendar';
import { ChurchService } from 'app/service/auth-service/church.service';
import { map, mergeMap } from 'rxjs/operators';


const screenfull = require('screenfull');

@Component({
   selector: 'gene-layout',
   templateUrl: './main-material.html',
   styleUrls: ['./main-material.scss'],
   animations: [
      routeAnimation,
      fadeInRightAnimation(),
   ],
   encapsulation: ViewEncapsulation.None
})

export class MainComponent implements OnInit, OnDestroy {

   currentUrl: any;
   root: any = 'ltr';
   layout: any = 'ltr';
   currentLang: any = 'en';
   customizerIn = false;
   showSettings = false;
   chatpanelOpen = false;
   sidenavOpen = true;
   isMobile = false;
   isFullscreen = false;
   collapseSidebarStatus: boolean;
   header: string;
   url: string;
   dark: boolean;
   compactSidebar: boolean;
   isMobileStatus: boolean;
   sidenavMode = 'side';
   popupDeleteResponse: any;
   sidebarColor: any;
   isSidebarFilterClass: string;
   isHeaderFilterClass: string;
   deviceInfo = null;
   private _mediaSubscription: Subscription;
   private _routerEventsSubscription: Subscription;
   private _router: Subscription;
   @ViewChild('sidenav') sidenav;
   end = 'end';

   popUpNewFamilyResponse;
   popUpNewMemberResponse;
   popUpNewUserResponse;
   data: any;
   selectedDate = new Date();
   eventListForCalendar: CalendarEvent[] = [];
   refresh: Subject<any> = new Subject();
   isLoadingAnnouncementList: Boolean;
   announcementList = [];
   announcementFromDate;
   announcementTODate;
   currentDate;


   version = 'NIV';
   animationState: boolean;

   loadingRouteConfig: boolean;

   verseData = 'I have set the LORD always before me; Because He is at my right hand I shall not be moved.'

   // verseData = 'I have set the LORD always before me; because he is at my right hand, I shall not be shaken';
   sourceName = 'Psalms 16:8';

   year = new Date().getFullYear();

   appInfo = AppConfig.settings.appInfo;

   sideBarFilterClass: any = [
      {
         sideBarSelect: 'sidebar-color-1',
         colorSelect: 'sidebar-color-dark'
      },
      {
         sideBarSelect: 'sidebar-color-2',
         colorSelect: 'sidebar-color-primary',
      },
      {
         sideBarSelect: 'sidebar-color-3',
         colorSelect: 'sidebar-color-accent'
      },
      {
         sideBarSelect: 'sidebar-color-4',
         colorSelect: 'sidebar-color-warn'
      },
      {
         sideBarSelect: 'sidebar-color-5',
         colorSelect: 'sidebar-color-green'
      }
   ]

   headerFilterClass: any = [
      {
         headerSelect: 'header-color-1',
         colorSelect: 'header-color-dark'
      },
      {
         headerSelect: 'header-color-2',
         colorSelect: 'header-color-primary'
      },
      {
         headerSelect: 'header-color-3',
         colorSelect: 'header-color-accent'
      },
      {
         headerSelect: 'header-color-4',
         colorSelect: 'header-color-warning'
      },
      {
         headerSelect: 'header-color-5',
         colorSelect: 'header-color-green'
      }
   ]
   getUserDetails: any;
   churchLogo: any;

   constructor(
      public menuItems: MenuItems,
      private pageTitleService: PageTitleService,
      public translate: TranslateService,
      private router: Router,
      private media: MediaObserver,
      private deviceService: DeviceDetectorService,
      private authService: AuthService,
      public coreService: CoreService,
      public dashboardService: DashboardService,
      public memberService: MemberService,
      private toastr: ToastrService,
      private announcementService: AnnouncementService,
      private eventsService: EventsService,
      private churchService: ChurchService,
   ) {

      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

   }

   ngOnInit() {
      this.pageTitleService.title.subscribe((val: string) => {
         this.header = val;
      });
      let churchLogo = JSON.parse(localStorage.getItem('churchLogo'))
      if (churchLogo) {
         this.churchLogo = churchLogo
      }
      else {
         this.churchLogo = 'assets/img/logo-with-grey-bg.svg'
      }
      this.getLoggedUserImage();
      // this.getVerseData();

      setTimeout(() => {
         if ((this.router.url === '/dashboard/courses' || this.router.url === '/courses/courses-list' ||
            this.router.url === '/courses/course-detail') && window.innerWidth < 1920) {
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            document.getElementById('main-app').className += ' sidebar-overlay';
         } else if ((this.router.url === '/ecommerce/shop' || this.router.url === '/ecommerce/checkout' ||
            this.router.url === '/ecommerce/invoice') && window.innerWidth < 1920) {
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            // for responsive
            document.getElementById('main-app').className += ' sidebar-overlay';
         } else if (window.innerWidth > 1280) {
            this.coreService.sidenavMode = 'side';
            this.coreService.sidenavOpen = true;
            // for responsive
            const main_div = document.getElementsByClassName('app');
            for (let i = 0; i < main_div.length; i++) {
               if (main_div[i].classList.contains('sidebar-overlay')) {
                  document.getElementById('main-app').classList.remove('sidebar-overlay');
               }
            }
         } else if (window.innerWidth < 1280) {
            const main_div = document.getElementsByClassName('app');
            for (let i = 0; i < main_div.length; i++) {
               if (!(main_div[i].classList.contains('sidebar-overlay'))) {
                  document.getElementById('main-app').className += ' sidebar-overlay';
               }
            }
         }
      }, 0)

      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
         this.url = event.url;
      });

      if (this.url !== '/login' && this.url !== '/session/register' &&
         this.url !== '/session/change-password' && this.url !== '/session/lockscreen') {
         const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container ')
      }

      this.deviceInfo = this.deviceService.getDeviceInfo();
      if (this.deviceInfo.device === 'ipad' || this.deviceInfo.device === 'iphone' || this.deviceInfo.device === 'android') {
         this.coreService.sidenavMode = 'over';
         this.coreService.sidenavOpen = false;
         document.getElementById('main-app').className += ' sidebar-overlay';
      }

      this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
         this.isMobileStatus = (change.mqAlias === 'xs') || (change.mqAlias === 'sm') || (change.mqAlias === 'md');
         this.isMobile = this.isMobileStatus;
         if (this.isMobile || ((this.router.url === '/ecommerce/shop' || this.router.url === '/ecommerce/checkout' ||
            this.router.url === '/ecommerce/invoice' || this.router.url === '/dashboard/courses' ||
            this.router.url === '/courses/courses-list' || this.router.url === '/courses/course-detail') && window.innerWidth < 1920)) {
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            // for responsive
            const main_div = document.getElementsByClassName('app');
            for (let i = 0; i < main_div.length; i++) {
               if (!(main_div[i].classList.contains('sidebar-overlay'))) {
                  if (document.getElementById('main-app')) {
                     document.getElementById('main-app').className += ' sidebar-overlay';
                  }
               }
            }
         } else {
            this.coreService.sidenavMode = 'side';
            this.coreService.sidenavOpen = true;
            // for responsive
            const main_div = document.getElementsByClassName('app');
            for (let i = 0; i < main_div.length; i++) {
               if (main_div[i].classList.contains('sidebar-overlay')) {
                  document.getElementById('main-app').classList.remove('sidebar-overlay');
               }
            }
         }
      });

      this._routerEventsSubscription = this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd && this.isMobile) {
            this.sidenav.close();
         }

         if (event instanceof RouteConfigLoadStart) {
            this.loadingRouteConfig = true;
            this.pageTitleService.setTitle('');
         } else if (event instanceof RouteConfigLoadEnd) {
            this.loadingRouteConfig = false;
         }
      });
   }

   getLoggedUserImage() {
      this.getUserDetails = this.authService.getUserRole();
   }

   ngOnDestroy() {
      this._router.unsubscribe();
      this._mediaSubscription.unsubscribe();
   }

   getVerseData() {

      this.animationState = false;
      setTimeout(() => {
         this.animationState = true;
         //   this.animationWithState = !this.animationWithState;
      }, 1);

      this.dashboardService.getVerses().subscribe((data) => {
         if (data && data.verse && data.verse.details) {
            this.verseData = data.verse.details.text;
            this.sourceName = data.verse.details.reference;
            this.version = data.verse.details.version;
         }
      }, err => {
         console.log('err', err)
      })
   }

   /**
     *As router outlet will emit an activate event any time a new component is being instantiated.
     */
   onActivate(e, scrollContainer) {
      this.getVerseData();
      scrollContainer.scrollTop = 0;
      if ((this.router.url === '/dashboard/courses' || this.router.url === '/courses/courses-list' ||
         this.router.url === '/courses/course-detail') && window.innerWidth < 1920) {
         this.coreService.sidenavMode = 'over';
         this.coreService.sidenavOpen = false;
         document.getElementById('main-app').className += ' sidebar-overlay';
      } else if ((this.router.url === '/ecommerce/shop' || this.router.url === '/ecommerce/checkout' ||
         this.router.url === '/ecommerce/invoice') && window.innerWidth < 1920) {
         this.coreService.sidenavMode = 'over';
         this.coreService.sidenavOpen = false;
         // for responsive
         document.getElementById('main-app').className += ' sidebar-overlay';
      } else if (window.innerWidth > 1280) {
         this.coreService.sidenavMode = 'side';
         this.coreService.sidenavOpen = true;
         // for responsive
         const main_div = document.getElementsByClassName('app');
         for (let i = 0; i < main_div.length; i++) {
            if (main_div[i].classList.contains('sidebar-overlay')) {
               document.getElementById('main-app').classList.remove('sidebar-overlay');
            }
         }
      }
      if (this.router.url === '/dashboard/crypto' || this.router.url === '/crypto/marketcap' ||
         this.router.url === '/crypto/wallet' || this.router.url === '/crypto/trade') {
         this.collapseSidebarStatus = this.coreService.collapseSidebar;
         if ((this.collapseSidebarStatus === false) && (window.innerWidth > 1280)) {
            document.getElementById('main-app').className += ' collapsed-sidebar';
            this.coreService.collapseSidebar = true;
         }
      } else if ((window.innerWidth > 1280)) {
         document.getElementById('main-app').classList.remove('collapsed-sidebar');
         this.coreService.collapseSidebar = false;
      }
   }

   /**
     * toggleFullscreen method is used to show a template in fullscreen.
     */
   toggleFullscreen() {
      if (screenfull.enabled) {
         screenfull.toggle();
         this.isFullscreen = !this.isFullscreen;
      }
   }

   /**
     * customizerFunction is used to open and close the customizer.
     */
   customizerFunction() {
      this.customizerIn = !this.customizerIn;
   }

   /**
     * addClassOnBody method is used to add a add or remove class on body.
     */
   addClassOnBody(event) {
      const body = document.body;
      if (event.checked) {
         body.classList.add('dark-theme-active');
      } else {
         body.classList.remove('dark-theme-active');
      }
   }

   /**
     * addMenuItem is used to add a new menu into menu list.
     */
   addMenuItem(): void {
      this.menuItems.add({
         state: 'pages',
         name: 'GENE MENU',
         type: 'sub',
         icon: 'trending_flat',
         children: [
            { state: 'blank', name: 'SUB MENU1' },
            { state: 'blank', name: 'SUB MENU2' }
         ]
      });
   }

   /**
     * changeRTL method is used to change the layout of template.
     */
   changeRTL(isChecked) {
      if (isChecked) {
         this.layout = 'rtl'
      } else {
         this.layout = 'ltr'
      }
   }

   /**
     * toggleSidebar method is used a toggle a side nav bar.
     */
   toggleSidebar() {
      this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
   }

   /**
     * logOut method is used to log out the  template.
     */
   logOut() {
      this.authService.logOut();
   }

   /**
     * sidebarFilter function filter the color for header section.
     */
   sidebarFilter(selectedFilter) {
      document.getElementById('main-app').classList.remove('sidebar-color-dark');
      this.isSidebarFilterClass = selectedFilter.colorSelect;
      document.querySelector('.radius-circle').classList.remove('radius-circle');
      document.getElementById(selectedFilter.sideBarSelect).classList.add('radius-circle');
   }

   /**
     * sidebarFilter function filter the color for header section.
     */
   headerFilter(selectedFilter) {
      document.getElementById('main-app').classList.remove('header-color-dark');
      this.isHeaderFilterClass = selectedFilter.colorSelect;
      document.querySelector('.radius-active').classList.remove('radius-active');
      document.getElementById(selectedFilter.headerSelect).classList.add('radius-active');
   }

   onClickFamily() {
      this.churchService.getChurchLicenseDetails().subscribe(data => {
         if (data.remainingMemberCount === 0) {
            this.toastr.error('Please upgrade your license to add New Family/Member')
         } else {
            this.coreService.addFamily(data).
               subscribe(res => { this.popUpNewFamilyResponse = res },
                  err => console.log(err),
                  () => this.getAddFamily(this.popUpNewFamilyResponse))

         }
      })

   }

   getAddFamily(response?: any) {
      if (response) {
         const addFamily = {
            name: response.salutation + '.' + response.name,   
      contactNumber1: response.mobileNumber || '',        
      contactCode1: response.mobileCode || null,         
      formNumber: response.formno,                        
      members: [                                           
        {
          firstName: response.name,                      
          gender: response.gender                        
        }]

         }
         this.memberService.quickSaveFamily(addFamily).subscribe(
            success => {
               this.toastr.success('Successfully added ' + response.name)

            }, err => {
               console.log('err', err)
               let errorString = 'Something went wrong while adding ' + response.name + '. Please refresh the page';
               if (err && err.error[0] && err.error[0].errorMessage) {
                  errorString = err.error[0].errorMessage;
               }
               this.toastr.error(errorString)
            });
      }

   }

   onClickMember() {
      this.churchService.getChurchLicenseDetails().subscribe(data => {
         if (data.remainingMemberCount === 0) {
            this.toastr.error('Please upgrade your license to add New Family/Member')
         } else {
            this.coreService.addMember(data).
               subscribe(res => { this.popUpNewFamilyResponse = res },
                  err => console.log(err),
                  () => this.getAddMember(this.popUpNewFamilyResponse))

         }
      })
   }

   getAddMember(response?: any) {
      let addMember = {};
      if (response) {
         if (response.mobileNumber) {
            addMember = {
               id: response.id,
               name: response.salutation + '.' + response.name,
               contactNumber1: response.mobileNumber,
               contactCode1: response.mobileCode,
               members: [                                           
                  {
                    firstName: response.name,                      
                    gender: response.gender                        
                  }]
          
            }
         } else {
            addMember = {
               id: response.id,
               name: response.salutation + '.' + response.name,
               members: [                                           
                  {
                    firstName: response.name,                      
                    gender: response.gender                        
                  }]
          
            }

         }
         this.memberService.quickSaveFamily(addMember).subscribe(
            success => {
               this.toastr.success('Successfully added ' + response.name)

            }, err => {
               console.log('err', err)
               let errorString = 'Something Went Wrong Please Refresh the Page';
               if (err && err.error[0] && err.error[0].errorMessage) {
                  errorString = err.error[0].errorMessage;
               }
               this.toastr.error(errorString)
            });
      }

   }

   getMemberCount() {
      this.churchService.getChurchLicenseDetails().subscribe(data => {
         if (data.remainingMemberCount === 0) {
            this.toastr.error('Please upgrade your license to add New Family/Member')
         }
      })
   }

   onClickEvent() {
      const data = {
         eventType: 'Add',
         eventDate: this.selectedDate
      }
      this.coreService.openEventPopup(data).
         subscribe(res => {
            if (res) {
               this.saveEvent(res.value)
            }
         })
   }
   saveEvent(formValue) {
      const sendData = {
         eventName: formValue.eventName,
         eventDescription: formValue.eventDescription,
         eventDateTime: ConvertDateTimeFromUTC(formValue.eventDate, 'YYYY-MM-DD HH:mm:ss'),
         attachment: formValue.attachment,
         fileSize: formValue.fileSize,
         id: 0,
         active: true
      }
      this.eventsService.saveChurchEvent(sendData).subscribe(data => {
         this.toastr.success("Event added successfully");
      }, error => {
         this.toastr.error("Something went wrong.please try again")
      })
   }

   onClickAnnouncement(ele) {
      this.coreService.addNewClientDialog(ele, '').
         subscribe(res => {
            if (res) {
               this.popUpNewUserResponse = res;
               this.saveAnnouncement(this.popUpNewUserResponse, 'save')
            }
         }, error => {
            console.log(error)
         })
   }
   saveAnnouncement(ele, method) {
      if (method === 'save') {
         const sendObj = {
            "announcement": ele.title,
            "announcementDescription": ele.description,
            "fromDateTime": ele.startDate,
            "toDateTime": ele.endDate,
            "attachment": ele.image,
            "fileSize": ele.fileSize,
         }
         this.announcementService.saveChurchAnnouncement(sendObj).subscribe(data => {
            this.toastr.success('Added Successfully');
         }, err => {
            console.log(err, 'error')
         })
      }
   }

   onClickHelp() {
      this.router.navigate(['/asse'])
   }
}




