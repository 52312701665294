import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class AuctionTypeService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllAuctiontype(isactive: boolean) {
        if (isactive) {
            return this.http.get<any>(this.authService._getAllAuctiontype + '?active=' + isactive);
          } else {
            return this.http.get<any>(this.authService._getAllAuctiontype);
          }
    }

    public getAuctiontypeById(id): Observable<any> {
      return this.http.get<any>(this.authService._getAuctiontypeById + '?id=' + id)
    }

    public saveAuctiontype(data) {
        return this.http.post(this.authService._saveAuctiontype, data);
    }

    public updateAuctiontype(data) {
        return this.http.put(this.authService._updateAuctiontype, data)
    }

    public auctionTypeUpdatesequence(data) {
        return this.http.put(this.authService._auctionTypeUpdatesequence, data)
    }

    public deleteAuctiontype(id) {
        return this.http.delete(this.authService._deleteAuctiontype + '?id=' + id)
    }

    public auctionTypeDeactivatebyid(id) {
        return this.http.put(this.authService._auctionTypeDeactivatebyid + '?id=' + id, '')
    }
    
    public auctionTypeActivatebyid(id) {
        return this.http.put(this.authService._auctionTypeActivatebyid + '?id=' + id, '')
    }

}