import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';;
import { Moment } from 'moment';
import { ConvertDateTimeFromUTC } from 'app/shared/utils/common-functions';
import { environment } from 'environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from 'app/service/auth-service/event.service';

@Component({
  selector: 'ms-event-popup',
  templateUrl: './event-popup.component.html',
  styleUrls: ['./event-popup.component.scss']
})

export class EventPopupComponent implements OnInit {
  @ViewChild('picker1') private picker: NgxMatDatetimePicker<Moment>;
  current_environment: String = environment.current_environment;
  imageChangedEvent: any = '';
  selectedImageFormData: any
  // minDate = new Date();
   minDate: Date | null = null;

  eventsDate = new Date();
  isLoading: boolean;

  form: FormGroup;
  popupDetails: any;

  constructor(public formBuilder: FormBuilder, private toastr: ToastrService, private eventsService: EventsService,
    public dialogRef: MatDialogRef<EventPopupComponent>) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      eventName: [this.popupDetails.eventName ? this.popupDetails.eventName : '', [Validators.required]],
      eventDescription: [this.popupDetails.eventDescription ? this.popupDetails.eventDescription : ''],
      eventDate: [this.popupDetails.eventDate ? new Date(this.popupDetails.eventDate) : '', Validators.required],
      id: [this.popupDetails.id ? this.popupDetails.id : 0],
      eventType: [this.popupDetails.eventType],
      eventTime: [this.popupDetails.eventTime ? this.popupDetails.eventTime : ''],
      eventNewDate: [null],
      isImageCropped: false,
      isImageSelected: false,
      attachment: [this.popupDetails.attachment ? this.popupDetails.attachment : ''],
      fileSize: [this.popupDetails.fileSize ? this.popupDetails.fileSize : 0],
    });
    if (this.popupDetails.eventType === "Add" && (ConvertDateTimeFromUTC(this.popupDetails.eventDate, 'DD-MM-YYYY') === ConvertDateTimeFromUTC(new Date(), 'DD-MM-YYYY'))) {
      this.eventsDate.setSeconds(0, 0)
      this.form.patchValue({
        eventDate: this.eventsDate.toISOString()
      })


    }
  }

  imageCropped(event: ImageCroppedEvent, form) {
    const blob = this.dataURItoBlob(event.base64);
    this.selectedImageFormData = new FormData();
    this.selectedImageFormData.append("file", blob);

    form.patchValue({
      isImageCropped: true,
      isImageSelected: true,
      attachment: event.base64,
    });
    this.form.updateValueAndValidity();
  }

  removeImage() {
    this.selectedImageFormData = null;
    this.form.patchValue({
      attachment: '',
      fileSize: 0,
      isImageCropped: false,
      isImageSelected: false,
    });
    this.form.updateValueAndValidity();
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  onImageUpload(event: any, form) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const maxFileSize = 1024 * 1024 * 2;
      if (file.size < maxFileSize) {
        this.imageChangedEvent = event;
        form.patchValue({
          isImageCropped: true,
          isImageSelected: true,
        });
        this.form.updateValueAndValidity();
      } else {
        this.toastr.error('File size is exceeded..., File Size Should be 2MB');
      }
    }
  }

  _openDatepickerOnClick(picker1: NgxMatDatetimePicker<Moment>) {
    // if (!picker1.opened) {
    picker1.open();
    // }
  }

  updateEvent() {
    this.isLoading = true;
    if (this.selectedImageFormData) {
      if(this.current_environment === 'S3')
        { 
            this.eventsService.uploadEventAttachment(this.selectedImageFormData).subscribe((file) => {
        this.form.patchValue({
          attachment: file.fileLink,
          fileSize: file.fileSize,
        });
        this.form.updateValueAndValidity();

        this.isLoading = false;
        this.dialogRef.close(this.form);
      }, err => {
        console.log('Error:', err);
        this.toastr.error('Something went wrong while uploading event Image. Please try again later');
        this.isLoading = false;
      });}
      else{
        this.eventsService.uploadchurcheventimage(this.selectedImageFormData).subscribe((file) => {
          this.form.patchValue({
            attachment: file.fileLink,
            fileSize: file.fileSize,
          });
          this.form.updateValueAndValidity();
  
          this.isLoading = false;
          this.dialogRef.close(this.form);
        }, err => {
          console.log('Error:', err);
          this.toastr.error('Something went wrong while uploading event Image. Please try again later');
          this.isLoading = false;
        });
      }
    } else {
      this.dialogRef.close(this.form);
    }
  }

  deleteEvent() {
    this.dialogRef.close(this.popupDetails)
  }

  closeDialog() {
    this.dialogRef.close('');
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}
