import * as moment from 'moment';

export class AuctionList {
    deserialize(input: any) {

        const splitData = input.name ? input.name.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = '';

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        const obj = {
            // churchId: input.churchId,
            auctionId: input.auctionId,
            slipNumber: input.slipNumber,
            auctionDate: input.auctionDate,
            auctionItem: input.auctionItem,
            auctionAmount: input.auctionAmount,
            auctionType: input.auctionType,
            auctionTypeId: input.auctionTypeId,
            coverNumber: input.coverNumber,
            year: input.year,
            AuctionName: (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace,
            name: input.name ? fullNameWithSpace : '',
            salution: input.name ? nameArray : '',
            contactNumber: input.contactNumber,
            contactCode:input.contactCode,
            id: input.id,
            active: input.active,
            memberId: input.memberId
        }
        return obj
    }
}

export class AuctionItem {
    deserialize(input: any) {

        const obj = {
            // churchId: input.churchId,
            name: input.name,
            contactNumber: input.contactNumber,
            contactCode: input.contactCode,
            auctionId: input.auctionId,
            slipNumber: input.slipNumber,
            auctionDate: input.auctionDate,
            auctionItem: input.auctionItem,
            auctionAmount: input.auctionAmount,
            coverNumber: input.coverNumber,
            year: input.year,
            auctionType: input.auctionType,
            auctionTypeId: input.auctionTypeId,
            memberId: input.memberId,
            id: input.id,
            active: input.active
        }
        return obj
    }
}

export class AuctionPayment {
    deserialize(input: any) {

        const sendObj = {
            // churchId: input.churchId,
            auctionId: input.auctionId,
            paymentTypeId: input.paymentTypeId,
            coverNumber: input.coverNumber,
            year: input.year,
            paidOn: input.paidOn,
            amountPaid: input.amountPaid,
            transactionNumber: input.transactionNumber,
            balanceAmount: input.balanceAmount,
            id: input.id,
            active: input.active
        }
        return sendObj
    }
}

export class AuctionByCover {
    deserialize(input: any) {     
        if (input) {
            // data.forEach(input => {
                const splitData = input.name ? input.name.split('.') : []
                let fullNameWithSpace = '';
                let nameArray = '';

                if (splitData.length > 1) {
                    nameArray = splitData[0];
                    delete splitData[0]
                    splitData.forEach((name, i) => {
                        fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
                    });
                } else if (splitData.length > 0 && splitData.length === 1) {
                    fullNameWithSpace = splitData[0]
                }

                const obj = {
                    // churchId: input.churchId,
                    coverNumber: input.coverNumber,
                    year: input.year,
                    auctionType: input.auctionType,
                    auctionTypeId: input.auctionTypeId,
                    isPaid: input.isPaid,
                    totalAuctionAmount: input.totalAuctionAmount,
                    totalAmountPaid: input.totalAmountPaid,
                    name: (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace,
                    Name: input.name ? fullNameWithSpace : '',
                    salution: input.name ? nameArray : '',
                    contactNumber: input.contactNumber,
                    contactCode:input.contactCode,
                    auctionItems: input.auctionItems,
                    auctionPayments: input.auctionPayments,
                    id: input.id,
                    active: input.active,
                    memberId: input.memberId
                }
                return obj;
           // })
        }
    }
}


export class OutstandingExcelJSOn {
    deserialize(input: any, tableHeaders) {
        const resObject = {};
        const splitData = input.name ? input.name.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = '';

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }
        tableHeaders.forEach(element => {
            if (element.columnName === 'Name') {
                resObject[element.columnName] = input.name ? fullNameWithSpace : '';
            } else {
                resObject[element.columnName] = input[element.columnId]
            }

        });
        return resObject;
    }
}

export class OutstandingResponseModel {
    deserialize(input: any) {
        const splitData = input.name ? input.name.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = '';

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        const obj = {
            id: input.id,
            amountPaid: input.amountPaid,
            auctionDate: input.auctionDate,
            balanceAmount: input.balanceAmount,
            contactNumber: input.contactNumber,
            contactCode:input.contactCode,
            coverNumber: input.coverNumber,
            auctionType: input.auctionType,
            auctionTypeId: input.auctionTypeId,
            Name: input.name ? fullNameWithSpace : '',
            outStandingMemberName: (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace,
            name: input.name,
            salution: input.name ? nameArray : '',
            slipNumber: input.slipNumber
        }
        return obj
    }
}

export class AuctionOutstandingList {
    deserialize(input: any) {
        let outstandingModal = [];

        if (input && input.models && input.models.length > 0) {
            outstandingModal = input.models.map((list) => new OutstandingResponseModel().deserialize(list));
        }

        const obj = {
            count: input.count,
            outstandingResponseModels: outstandingModal,
            totalAmountReceived: input.totalAmountReceived,
            totalBalance: input.totalBalance
        }
        return obj
    }
}

export class GetCoverList {
    deserialize(input: any) {
        const obj = {
            // churchId: input.churchId,
            coverNumber: input.coverNumber,
            description: input.description,
            memberName: input.memberName,
            id: input.id,
            active: input.active,
            memberId: input.memberId
        }
        return obj
    }
}

export class AuctionExcelJSON {
    deserialize(input: any, tableHeaders) {
        if (input) {
            const reqObject = {};
            const splitData = input.name ? input.name.split('.') : []
            let fullNameWithSpace = '';
            let nameArray = '';

            if (splitData.length > 1) {
                nameArray = splitData[0];
                delete splitData[0]
                splitData.forEach((name, i) => {
                    fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
                });
            } else if (splitData.length > 0 && splitData.length === 1) {
                fullNameWithSpace = splitData[0]
            }

            tableHeaders.forEach((element: any) => {
                if (element.columnName === 'Date') {
                    reqObject[element.columnName] = input[element.columnId] ? moment(input[element.columnId].split('T')[0], 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
                } else if (element.columnName === 'Name') {
                    reqObject[element.columnName] = (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace;
                } else {
                    reqObject[element.columnName] = input[element.columnId];
                }

            });

            return reqObject;
        }
    }
}

export class AuctionByCoverNumberExcelJSON {
    deserialize(input: any, tableHeaders) {
        if (input) {
            const reqObject = {};
            tableHeaders.forEach((element: any) => {
                reqObject[element.columnName] = input[element.columnId];
            });

            return reqObject;
        }
    }
}
