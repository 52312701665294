import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChurchService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  // public getChurchDetails(): Observable<any> {
  //   return this.http.get<any>(this.authService._getAllChurchList);
  // }

  public getChurch(): Observable<any> {
    // return this.http.get<any>(this.authService._getChurchbyId + '?id=' + id);
    return this.http.get<any>(this.authService._getChurch);
  }

  public addChurchDetails(data) {
    return this.http.post(this.authService._addChurchDetail, data);
  }

  public updateChurchDetail(data) {
    return this.http.put(this.authService._updateChurchDetail, data);
  }

  public deleteChurchData(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteChurchDetail + '?id=' + deleteId);
  }

  public enableChurchDetail(Id,active) {
    return this.http.post(this.authService._enableChurchDetail + '?id=' + Id ,+'&active='+active);
  } 

 

  /*public ActiveChurchById(Id) {
    return this.http.put(this.authService._activeChurchById + '?id=' + Id, '');
  }*/

  public uploadChurchLogo(file): Observable<any> {
    return this.http.post(this.authService._uploadChurchLogo,
      file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }
  public uploadChurch(file): Observable<any> {
    return this.http.post(this.authService._uploadChurch,
      file, this.authService.getOptionsWithFormDataAsRequestFormat());
  }
  public getChurchLicenseDetails(): Observable<any> {
    return this.http.get<any>(this.authService.getLicenseDetails);
  }

}
