import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-ms-add-new-admin-user',
  templateUrl: './add-new-admin-user.component.html',
  styleUrls: ['./add-new-admin-user.component.scss']
})
export class AddNewAdminUserComponent implements OnInit {
  name;
  churchName;
  addNewUserForm: FormGroup
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  data: any;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNewAdminUserComponent>) { }

  ngOnInit() {
    this.addNewUserForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      displayName: ['', [Validators.required]],
      churchName: [{value: '',disabled: true}, [Validators.required]],
      roleId: ['', [Validators.required]],
      id: 0
    })

    const username = this.data.userName ;
    var atPosition = username.indexOf('@');
   
    if (atPosition > 0) {
      this.name = username.slice(0, atPosition);
      this.churchName = username.slice(atPosition);
    } else {
      this.name = this.data.userName;
    }
    
    this.addNewUserForm.patchValue({
      userName: this.name,
      displayName: this.data.displayName,
      churchName: this.data.churchName,
      roleId: this.data.userRole,
      id: this.data.id
    })
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
    this.dialogRef.close(this.addNewUserForm.value);
  }
}
