import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailFilter'
})
export class EmailFilterPipe implements PipeTransform {

  transform(items: any[], searchItem: any): any[] {
    if(items && items.length) {
    if (!searchItem || searchItem === '') {
      return items;
    }else{
      return items.filter(element => {
        if (element["name"]) {
          return element["name"].toLowerCase().includes(searchItem);
        }
      });
    }
}
  }

}
