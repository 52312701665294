import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { validateAllFormFields } from 'app/shared/utils/common-functions';

@Component({
	selector: 'ms-add-new-user',
	templateUrl: './add-new-user.component.html',
	styleUrls: ['./add-new-user.component.scss']
})
export class AddNewUserComponent implements OnInit {

	addNewUserForm: FormGroup;
	data: any;
	customOrderId: number;

	customSettingsForm: FormGroup;
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
	constructor(private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<AddNewUserComponent>) { }

	ngOnInit() {
		this.customSettingsForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			description: [''],
			order: [this.customOrderId, [Validators.required]],
		});

		this.addNewUserForm = this.formBuilder.group({
			firstname: ['', [Validators.required]],
			lastname: ['', [Validators.required]],
			emailAddress: ['', [Validators.required]],
			accountType: ['', [Validators.required]]
		});
	}

	onFormSubmit(form) {
		if (form.valid) {
			this.dialogRef.close(form.value);
		} else {
			validateAllFormFields(form)
		}
	}
}
