import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { }

    // Success Type
    typeSuccess(msg) {
        this.toastr.success(msg, '', {
            positionClass: 'toast-top-right'
        });
    }

    // Info Type
    info(msg) {
        this.toastr.info(msg);
    }

    // Warning Type
    warning(msg) {
        this.toastr.warning(msg);
    }

    // Error Type
    error(msg) {
        this.toastr.error(msg);
    }

    typeError(msg) {
        this.toastr.error(msg);
    }
 // custom Type
 typeCustom(colorcode, count, msg, ) {
    this.toastr.success('<span style="color:' + colorcode + '; font-size:20px; font-weight: 500">'
        + count + ''
        + '</span>' + '' + msg + '' + ' is given.', null, { enableHtml: true });
}
}
