import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllChurchAnnouncements(active) {
        return this.http.get<any>(this.authService._getAllChurchAnnouncements + '?active=' + active);
    }

    public getChurchAnnouncementById(id): Observable<any> {
      return this.http.get<any>(this.authService._getChurchAnnouncementById + '?id=' + id)
    }

    public saveChurchAnnouncement(data) {
        return this.http.post(this.authService._saveChurchAnnouncement, data);
    }

    public updateChurchAnnouncement(data) {
        return this.http.put(this.authService._updateChurchAnnouncement, data)
    }

    public deleteChurchAnnouncement(Id): Observable<any> {
      return this.http.delete(this.authService._deleteChurchAnnouncement + '?id=' + Id)
    }

    public uploadChurchAnnouncement(file): Observable<any> {
      return this.http.post(this.authService._uploadChurchAnnouncement, file);
  }

  public uploadchurchannouncementimage(file): Observable<any> {
    return this.http.post(this.authService._uploadchurchannouncementimage, file);
}

}