import { Directive, NgModule, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[msRegxPattern]'
})
export class RegxPatternDirective {

  regexStr = '^[0-9/]*$';

  constructor(private el: ElementRef) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[a-zA-Z ]/g, ' ').replace(/\s/g, ' ');

    }, 100)
  }
}

@NgModule({
  declarations: [RegxPatternDirective],
  exports: [RegxPatternDirective]
})

export class RegxPatternDirectiveModule { }
