
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ComponentCommunicationService {

    public memberReportPageConfig;
    public subscriptionReportPageConfig;
    public subscriptionNonPayerPageConfig
    public OfferingReportPageConfig;
    public printAuctionConfig;
    public printOutstandingConfig;
    public familyHeadReportPageConfig;
    public expenseReportPageConfig;
    public DonationReportPageConfig;

    public profileComponentSubject = new BehaviorSubject<any>({ id: 0 })
    memberPassingId = this.profileComponentSubject.asObservable();

    public userProfileComponents = new BehaviorSubject<any>({})
    userProfileData = this.userProfileComponents.asObservable();

    transferUserProfile(data) {
        this.profileComponentSubject.next(data)
    }

    getUserProfileData(data) {
        this.userProfileComponents.next(data)
    }
}
