import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ComponentCommunicationService } from '../../../service/core/compomentCommunication.service';
import { CoreService } from '../../../service/core/core.service';
import { Router } from '@angular/router';
import { FamilyService } from '../../../service/auth-service/family.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from 'app/service/auth-service/members.service';

@Component({
	selector: 'ms-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit, OnChanges {

	@Input() userProfile: any;
	userElements: any;
	isProfileMember: boolean;
	popUpDeleteUserResponse: any;

	constructor(private communicationService: ComponentCommunicationService, private coreService: CoreService, private router: Router,
		private familyService: FamilyService, private http: HttpClient, private toastr: ToastrService, private memberService: MemberService) { }

	ngOnInit() {
		this.communicationService.userProfileComponents.subscribe(
			data => {
				if (data && this.userProfile && this.userProfile.length > 0) {
					
					this.isProfileMember = false;
					this.userElements = data;
				} else {
					this.isProfileMember = true;
				}
			})
	}

	ngOnChanges() {
	}

	onEdituserProfile(user) {
		if (user.familyHeadList) {
			this.router.navigate(['/member/membereditprofile', user.familyHeadList.id])
		} else {
			this.router.navigate(['/member/addFamilyList'], { queryParams: { id: user.id } })
		}
	}

	onDeleteuserProfile(user) {

		this.coreService.deleteDialog('Are you sure want to delete ' + user.userName + ' permanently?', 'Delete').
			subscribe(res => {
				this.popUpDeleteUserResponse = res
			},
				err => console.log(err),
				() => this.getDeleteResponse(this.popUpDeleteUserResponse, user))
	}

	getDeleteResponse(response: any, ele) {
		if (response === 'yes') {
			if (ele.familyHeadList) {
				this.memberService.deleteMemberById(ele.familyHeadList.id)
					.subscribe((data) => {
						this.toastr.success(ele.userName + ' Member is deleted successfully');
						this.router.navigate(['/member/memberList']);
					}, error => {
						console.log(error);
						this.toastr.error('Something went wrong while delete ' + ele.userName + ' member. Please try again');
					});
			} else {
				this.familyService.deleteFamilyData(ele.id).subscribe(
					success => {
						this.toastr.success(ele.userName + ' ' + success)
						this.router.navigate(['/member/familyList'])
					}, err => {
						console.log('err', err)
						this.toastr.error(' ' + err.error[0].errorMessage)
					});
			}
		}
	}

	ondeactivate(user) {
		const sendObj = {
			member: user.familyHeadList,
			header: 'Deactivate Member'
		}
		this.coreService.deleteDialog('Are you sure that you want to deactivate the membership?', sendObj).
			subscribe(response => {
				if (response.value) {
					if (response.isChangeFamilyHead) {
						this.onChangeFamilyHead(response, user);
					} else {
						this.getdeactivateResponse(response.value.reason, user);
					}
				}
			},
				err => console.log(err))
	}

	onChangeFamilyHead(res, prevMember) {
		let sendObj = {}
		if (res.value.reason.reason === 'Dead') {
			sendObj = {
				"oldMemberId": prevMember.familyHeadList.id,
				"newMemberId": res.value.familyHead.id,
				"inActiveReason": res.value.reason.reason + ',' + 'deathDate:' + res.value.reason.deathDate
			}
		} else {
			sendObj = {
				"oldMemberId": prevMember.familyHeadList.id,
				"newMemberId": res.value.familyHead.id,
				"inActiveReason": res.value.reason.reason
			}
		}

		this.familyService.onFamilyHeadChange(sendObj).subscribe(data => {
			this.getdeactivateResponse(res.value.reason, prevMember);
		}, error => {
			this.toastr.error("Something went wrong please try again")
		})
	}

	getdeactivateResponse(response: any, ele) {
		let sendObj = {};
		if (response.reason === 'Dead') {
			sendObj = {
				"id": ele.familyHeadList.id,
				"reason": response.reason,
				"deathDate": response.deathDate
			}
		} else {
			sendObj = {
				"id": ele.familyHeadList.id,
				"reason": response.reason
			}
		}

		this.memberService.deactivateMemberById(sendObj).subscribe(
			success => {
				this.toastr.success('Successfully deactivated ' + ele.familyHeadList.firstName)
				this.router.navigate(['/member/familyList'])
			}, err => {
				console.log('err', err)
				this.toastr.error(' ' + err.error[0].errorMessage)
			});
	}
	activateEntireFamily(user) {
		// console.log(user)
		this.familyService.ActivateEntireFamilyById(user.id).subscribe(data => {
		  if (data) {
			const currentUrl = this.router.url;
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			  this.router.navigateByUrl(currentUrl);
			this.toastr.success('Successfully Activated ' + user.userName)
			})
		  }
		})
	  }
}
