import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { DashboardService } from 'app/service/auth-service/dashboard.service';
import { FamilyService } from 'app/service/auth-service/family.service';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { validateAllFormFields } from 'app/shared/utils/common-functions';
import { ToastrService } from 'ngx-toastr';
import { addMemberJson } from 'app/widget-component/pop-up/add-member/add-member.modal';

@Component({
  selector: 'ms-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  addNewMember: FormGroup;
  data: any;
  isLoading: boolean;
  isSearchEmpty: boolean;
  isActiveMember = true;
  usermanagelist = [];
  valueOfZero: boolean;
  length;
  isoCode;
  countriesList;
  salutationArray = [
    'Mr',
    'Ms',
    'Mrs',
    'Miss',
    'Master',
    'Baby',
    'Dr',
    'Rev',
    'Pastor',
    'Bishop',
    'Rt. Rev',
    'Capt',
    'Col',
    'Lt. Col',
    'Maj',
    'Cdr',
    'Chief Justice',
    'Brig',
    'Gp. Capt',
    'Adv',
    'Sis',
    'Bro'
  ]
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddMemberComponent>,
    private familyService: FamilyService, private filterTextPipe: FilterTextPipe, private toastr: ToastrService) { }

  ngOnInit() {
    this.getCountryCodes();
    this.createform();

  }


  getCountryCodes() {
    this.familyService
      .getCountryDetails<any>()
      .subscribe(
        jsondata => {
          this.countriesList = jsondata;
          this.addNewMember
            .patchValue({
              countryCode: this.countriesList[97].dial_code
            })

        },
        errorResponse => {
          console.log('Error', errorResponse);
        });
  }

  createform() {
    this.addNewMember = this.fb.group({
      familyHeadName: ['', [Validators.required]],
      name: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      salutation:['',Validators.required],
      // mobileNumber: [''],
      mobileNumber: [null,[Validators.pattern('[0-9]{10}')]],
      countryCode: [null],
      id: 0
    });
  }

  searchFamilyFilterForm(sendObj) {
    this.isLoading = true;
    const columnSearch = {
      'offset': 0,
      'noOfRows': 10,
      'familySearch': this.addNewMember.value.familyHeadName.trim(),
      'columnName': 'Name',
      'active': true,
    }
    Object.assign(columnSearch, sendObj)
    this.familyService.searchFamilyByColums(columnSearch).subscribe(
      data => {
        this.isLoading = false;
        if (data && data.model.length > 0) {
          this.isSearchEmpty = false;
          this.usermanagelist = data.model.map((list) => {

            return new addMemberJson().deserialize(list)
          });
          this.length = data.count;
        } else {
          this.usermanagelist = [];
          this.isSearchEmpty = true;
          this.toastr.error('Please select the correct Family Head')
        }
      }, err => {
        console.log('err', err);
        this.isLoading = false;
        this.isSearchEmpty = true;
        this.valueOfZero = false;
        this.usermanagelist = []
        let errorString = 'Something went wrong while searching family List';

        if (err && err.error && err.error.length > 0 && err.error[0] && err.error[0].errorMessage) {
          errorString = err.error[0].errorMessage;
        }
        this.toastr.error(errorString)
      });
  }

  selectMember(member) {
    this.addNewMember.patchValue({
      familyHeadName: member.name,
      id: member.familyId
    })
  }

  selectedValue(event) {
    const countryIsoCode = this.countriesList.forEach(country => {
      if (event.source.triggerValue === country.name) {
        this.isoCode = country.code
      }
    })
  }
  onSelectSalutation(form) {
    const salutation = form.value.salutation;
    switch (salutation) {
      case 'Dr': {
        break;
      }
      case 'Mr':
        {
          form.patchValue({
            gender: 'M',
            // maritalStatus: 'Married'
          })
         
          break;
        }
      case 'Master': {
        form.patchValue({
          gender: 'M',
          // maritalStatus: 'Single'
        })
        break;
      }
      case 'Baby':
        {
          form.patchValue({
            gender: 'F',
            // maritalStatus: 'Single'
          })
          break;
        }
      case 'Miss': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
        
        break;
      }
      case 'Mrs': {
        form.patchValue({
          gender: 'F',
          //  maritalStatus: 'Married'
        })
       
        
        break;
      }
      case 'Sis': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
        break;
      }
      case 'Bro': {
        form.patchValue({
          gender: 'M',
          // maritalStatus: 'Single'
          })
        
          break;
      }
      case 'Ms': {
        form.patchValue({
          gender: 'F',
          // maritalStatus: 'Single'
        })
       
        break;
      }
  }
}

  onAddMember(form: FormGroup): void {
    if ( form.value.mobileNumber != "" &&  this.addNewMember.value.countryCode === "") { this.addNewMember.controls['countryCode'].setErrors({ 'incorrect': true }) }
    // else if ((form.value.mobileNumber === null && form.value.mobileNumber === "") && (form.value.countryCode != null || form.value.countryCode != "")) { form.controls['mobileNumber'].setErrors({ 'incorrect': true }); }
    else { this.addNewMember.controls['countryCode'].setErrors(null); }
    if (form.valid) {
      
      const sendObj = {
        familyHeadName: form.value.familyHeadName,
        name: form.value.name,
        salutation:form.value.salutation,
        mobileCode: form.value.mobileNumber ? form.value.countryCode : null,
        mobileCodeISO: form.value.mobileNumber ? this.isoCode : null,
        mobileNumber: form.value.mobileNumber,
        id: form.value.id,
        gender: form.value.gender
      }
      this.dialogRef.close(sendObj);
    } else {
      validateAllFormFields(form)
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof AbstractControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }
}




