import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AddNewUserComponent } from '../../widget-component/pop-up/add-new-user/add-new-user.component';
import { EditNewUserComponent } from '../../widget-component/pop-up/edit-new-user/edit-new-user.component';
import { DeleteDialogComponent } from '../../widget-component/pop-up/delete-dialog/delete-dialog.component';

import { AddNewClientComponent } from '../../widget-component/pop-up/add-new-client/add-new-client.component';
import { AddNewCardComponent } from '../../widget-component/pop-up/add-new-card/add-new-card.component';
import { AddNewAdminUserComponent } from 'app/widget-component/pop-up/add-new-admin-user/add-new-admin-user.component';
import { ResetPasswordComponent } from 'app/widget-component/pop-up/reset-password/reset-password.component';
import { ImportInstructionsComponent } from 'app/widget-component/pop-up/import-instructions/import-instructions.component';
import { EventPopupComponent } from 'app/widget-component/pop-up/event-popup/event-popup.component';
import { AddFamilyComponent } from 'app/widget-component/pop-up/add-family/add-family.component';
import { AddMemberComponent } from 'app/widget-component/pop-up/add-member/add-member.component';
import { TransferMemberComponent } from 'app/widget-component/pop-up/transfer-member/transfer-member.component';
import { EnableUserComponent } from 'app/widget-component/pop-up/enable-user/enable-user.component';
import { MemberPopupComponent } from 'app/widget-component/pop-up/member-popup/member-popup.component';
import { EditMinistryComponent } from 'app/widget-component/pop-up/edit-ministry/edit-ministry.component';
@Injectable({
	providedIn: 'root'
})

export class CoreService {

	collapseSidebar = false;
	sidenavMode = 'side';
	sidenavOpen = true;
	horizontalSideNavMode = 'over';
	horizontalSideNavOpen = false;
	projectDetailsContent: any;
	editProductData: any;


	private updateMemberSubject = new BehaviorSubject<any>({ isUpdate: false, id: 0 });
	updateMemeberListpage = this.updateMemberSubject.asObservable()

	constructor(private matDialog: MatDialog, private router: Router,
		private http: HttpClient) {
	}

	// addNewAdminUser function is used to open Add New user Dialog Component. 
	addNewAdminUserDialog(data: any) {
		let dialogRef: MatDialogRef<AddNewAdminUserComponent>;
		dialogRef = this.matDialog.open(AddNewAdminUserComponent);
		dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
	}

	// addNewUserDailog function is used to open Add New user Dialog Component.
	addNewUserDailog(data: string, orderId: number) {
		let dialogRef: MatDialogRef<AddNewUserComponent>;
		dialogRef = this.matDialog.open(AddNewUserComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.customOrderId = orderId

		return dialogRef.afterClosed();
	}

	editMinistry(data:string, details:any) {
		let dialogRef: MatDialogRef<EditMinistryComponent>;
		dialogRef = this.matDialog.open(EditMinistryComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.ministryDetails = details;

		return dialogRef.afterClosed();
	}


	// addNewCl-liientDialog function is used to open Add new client Dialog Component.
	addNewClientDialog(data: boolean, coverNumber) {
		let dialogRef: MatDialogRef<AddNewClientComponent> = this.matDialog.open(AddNewClientComponent, { disableClose: true });
		// dialogRef = this.matDialog.open(AddNewClientComponent, { disableClose: true });
		dialogRef.componentInstance.isSelectedAdd = data;
		dialogRef.componentInstance.coverNumber = coverNumber;

		return dialogRef.afterClosed();
	}
	// addNewClientDialog(data: boolean, coverNumber: string) {
	// 	const dialogRef: MatDialogRef<AddAuctionComponent> = this.dialog.open(AddAuctionComponent, { disableClose: true });
	// 	dialogRef.componentInstance.isSelectedAdd = data;
	// 	dialogRef.componentInstance.coverNumber = coverNumber;
	
	// 	return dialogRef.afterClosed();
	//   }

	addNewCardDialog(title: string) {
		let dialogRef: MatDialogRef<AddNewCardComponent>;
		dialogRef = this.matDialog.open(AddNewCardComponent);

		dialogRef.componentInstance.title = title;

		return dialogRef.afterClosed();
	}


	addFamilyHeadDialog(title: string) {
		let dialogRef: MatDialogRef<AddNewCardComponent>;
		dialogRef = this.matDialog.open(AddNewCardComponent);

		dialogRef.componentInstance.title = title;

		return dialogRef.afterClosed();
	}

	openEventPopup(popupDetails) {
		let dialogRef: MatDialogRef<EventPopupComponent>;
		dialogRef = this.matDialog.open(EventPopupComponent, {
			width: '50%'
		});

		dialogRef.componentInstance.popupDetails = popupDetails;

		return dialogRef.afterClosed();
	}

	openDeleteEventPopup(popupDetails) {
		let dialogRef: MatDialogRef<EventPopupComponent>;
		dialogRef = this.matDialog.open(EventPopupComponent, {
			width: '40%'
		});

		dialogRef.componentInstance.popupDetails = popupDetails;

		return dialogRef.afterClosed();
	}

	resetPasswordDialog() {
		let dialogRef: MatDialogRef<ResetPasswordComponent>;
		dialogRef = this.matDialog.open(ResetPasswordComponent);
		return dialogRef.afterClosed();
	}

	enableUserDialog(data: any) {
		let dialogRef: MatDialogRef<EnableUserComponent>;
		dialogRef = this.matDialog.open(EnableUserComponent);
		dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
	}

	// editList function is used to open Edit Dialog Component.
	editList(data, title: string) {
		let dialogRef: MatDialogRef<EditNewUserComponent>;
		dialogRef = this.matDialog.open(EditNewUserComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.editTitle = title;

		return dialogRef.afterClosed();
	}

	updateMemberList(data) {
		this.updateMemberSubject.next(data);
	}

	// deleteDiaglog function is used to open the Delete Dialog Component.

	deleteDialog(data: string, memberDetails: any) {
		let dialogRef: MatDialogRef<DeleteDialogComponent>;
		dialogRef = this.matDialog.open(DeleteDialogComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.memberDetails = memberDetails;
		return dialogRef.afterClosed();
	}

	showImportInstructions(data) {
		let dialogRef: MatDialogRef<ImportInstructionsComponent>;
		dialogRef = this.matDialog.open(ImportInstructionsComponent);
		dialogRef.componentInstance.instructions = data;
		return dialogRef.afterClosed();
	}

	addFamily(data: any) {
		let dialogRef: MatDialogRef<AddFamilyComponent>;
		dialogRef = this.matDialog.open(AddFamilyComponent);
		dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
	}
	
	addMember(data: any) {
		let dialogRef: MatDialogRef<AddMemberComponent>;
		dialogRef = this.matDialog.open(AddMemberComponent);
		 dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
	}

	transferDialog(data: string, memberDetails: any) {
		let dialogRef: MatDialogRef<TransferMemberComponent>;
		dialogRef = this.matDialog.open(TransferMemberComponent);
		dialogRef.componentInstance.data = data;
		dialogRef.componentInstance.memberDetails = memberDetails;
		return dialogRef.afterClosed();
	}
	memberDialog(data: string, memberDetails: any) {
		let dialogRef: MatDialogRef<MemberPopupComponent>;
		dialogRef = this.matDialog.open(MemberPopupComponent);
		dialogRef.componentInstance.data = data;
		 dialogRef.componentInstance.memberDetails = memberDetails;
		return dialogRef.afterClosed();
	}
}