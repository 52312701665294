import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	MatCardModule, MatButtonModule, MatIconModule, MatDialogModule, MatAutocompleteModule, MatFormFieldModule, MatSelectModule, MatMenuModule, MatDividerModule,
	MatTabsModule, MatTableModule, MatSnackBarModule, MatInputModule, MatChipsModule, MatListModule, MatExpansionModule, MatTooltipModule, MatDatepickerModule, MatProgressSpinnerModule
} from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { ChartsModule } from 'ng2-charts';
import { QuillModule } from 'ngx-quill';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { EmbedVideo } from 'ngx-embed-video';
import { BarRatingModule } from 'ngx-bar-rating';
import { RouterModule } from '@angular/router';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EasyPieChartModule } from 'ng2modules-easypiechart';
import { DeleteDialogComponent } from './pop-up/delete-dialog/delete-dialog.component';
import { AddNewCardComponent } from './pop-up/add-new-card/add-new-card.component';

import { AddNewUserComponent } from './pop-up/add-new-user/add-new-user.component';
import { EditNewUserComponent } from './pop-up/edit-new-user/edit-new-user.component';
import { UserProfileComponent } from './user-profileV2/user-profile/user-profile.component';
import { PublicationsComponent } from './user-profileV2/publications/publications.component';
import { AddNewClientComponent } from './pop-up/add-new-client/add-new-client.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EmailStatisticsComponent } from './chart/email-statistics/email-statistics.component';
import { MatchHeightModule } from '../shared/directives/match-height.directive';
import { PipeModule } from '../shared/pipes/pipe.module';
import { SaasDashboardCardComponent } from './card/saas-dashboard-card/saas-dashboard-card.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { AddNewAdminUserComponent } from './pop-up/add-new-admin-user/add-new-admin-user.component';
import { ResetPasswordComponent } from './pop-up/reset-password/reset-password.component';
import { RegxPatternDirectiveModule } from 'app/shared/directives/regx-pattern.directive';
import{AcceptOnlyAlphabetsDirectiveModule} from 'app/shared/directives/acceptOnlyAlphabets.directive'
import { ImportInstructionsComponent } from './pop-up/import-instructions/import-instructions.component';
import { EventPopupComponent } from './pop-up/event-popup/event-popup.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageUploadModule } from 'angular2-image-upload';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AddMemberComponent } from './pop-up/add-member/add-member.component';
import { AddFamilyComponent } from './pop-up/add-family/add-family.component';
import { nvD3} from '../core/nvD3/nvD3.component';
import { StackedAreaChartComponent } from './chart/stacked-area-chart/stacked-area-chart.component';
import { MixedChartComponent } from './chart/mixed-chart/mixed-chart.component';
import { TransferMemberComponent } from './pop-up/transfer-member/transfer-member.component';
import { EnableUserComponent } from './pop-up/enable-user/enable-user.component';
import { MemberPopupComponent } from './pop-up/member-popup/member-popup.component';
import { EditMinistryComponent } from './pop-up/edit-ministry/edit-ministry.component';
import { DatePipe } from '@angular/common';
import { SelectMemberComponent } from './pop-up/select-member/select-member.component';
import { ConfirmationComponent } from './pop-up/confirmation/confirmation.component';
// import { ImageCropperModule } from 'ng2-img-cropper';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
	parse: {
	  dateInput: "DD/MM/YYYY, LTS"
	},
	display: {
	  dateInput: "DD/MM/YYYY, LTS",
	  monthYearLabel: "MMM YYYY",
	  dateA11yLabel: "LL",
	  monthYearA11yLabel: "MMMM YYYY"
	}
  };
@NgModule({
	declarations: [
		DeleteDialogComponent,
		AddNewCardComponent,
		AddNewUserComponent,
		EditNewUserComponent,
		UserProfileComponent,
		PublicationsComponent,
		SaasDashboardCardComponent,
		AddNewClientComponent,
		EmailStatisticsComponent,
		nvD3,
		StackedAreaChartComponent,
		MixedChartComponent,
		AddNewAdminUserComponent,
		ResetPasswordComponent,
		ImportInstructionsComponent,
		EventPopupComponent,
		AddMemberComponent,
		AddFamilyComponent,
		TransferMemberComponent,
		EnableUserComponent,
		MemberPopupComponent,
		EditMinistryComponent,
		SelectMemberComponent,
		ConfirmationComponent,
		
	],
	imports: [
		RouterModule,
		BarRatingModule,
		EasyPieChartModule,
		CommonModule,
		MatCardModule,
		FlexLayoutModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatchHeightModule,
		PipeModule,
		QuillModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatDialogModule,
		MatFormFieldModule,
		MatSelectModule,
		MatMenuModule,
		MatDividerModule,
		MatTabsModule,
		MatTableModule,
		FormsModule,
		ReactiveFormsModule,
		TextMaskModule,
		ChartsModule,
		PerfectScrollbarModule,
		MatSnackBarModule,
		SlickCarouselModule,
		TranslateModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		RegxPatternDirectiveModule,
		AcceptOnlyAlphabetsDirectiveModule,
		ImageCropperModule,
		ImageUploadModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMatNativeDateModule,
		MatMomentDateModule,
		NgxMatMomentModule,
		NgbModule,
		EmbedVideo.forRoot()

	],
	exports: [
		UserProfileComponent,
		PublicationsComponent,
		SaasDashboardCardComponent,
		EmailStatisticsComponent,
		StackedAreaChartComponent,
		MixedChartComponent,
	],
	entryComponents: [
		DeleteDialogComponent,
		AddNewCardComponent,
		AddNewUserComponent,
		EditNewUserComponent,
		AddNewClientComponent,
		AddNewAdminUserComponent,
		ResetPasswordComponent,
		ImportInstructionsComponent,
		EventPopupComponent,
		AddMemberComponent,
		AddFamilyComponent,
		TransferMemberComponent,
		EnableUserComponent,
		MemberPopupComponent,
		EditMinistryComponent,
        SelectMemberComponent,
		ConfirmationComponent
	],
	providers: [FilterTextPipe,  { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }, DatePipe],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class WidgetComponentModule { }
