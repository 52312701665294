import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CarolAreaService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllCarolAreaList(): Observable<any> {
    return this.http.get<any>(this.authService._getAllCarolArea);
  }

  public getCarolAreaModel(): Observable<any> {
    return this.http.get<any>(this.authService._getCarolAreaList);
  }

  public getAllAreaList(): Observable<any> {
    return this.http.get<any>(this.authService._getAllArea);
  }

  public getAreabyId(AreaId): Observable<any> {
    return this.http.get<any>(this.authService._getAreaById + '?id=' + AreaId);
  }

  public getByCarolAreaby(CarolArea): Observable<any> {
    return this.http.get<any>(this.authService.__getByCarolArea + '?carolArea=' + CarolArea);
  }

  public addCarolAreaDetails(data) {
    return this.http.post(this.authService._addCarolArea, data);
  }

  public updateCarolAreaDetail(data) {
    return this.http.put(this.authService._updateCarolArea, data);
  }

  public deleteCarolAreaData(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteCarolArea + '?id=' + deleteId);
  }

  public removeCarolArea(deleteId): Observable<any> {
    return this.http.put(this.authService._removeCarolArea + '?id=' + deleteId, '');
  }
}
