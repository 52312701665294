import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { validateAllFormFields } from 'app/shared/utils/common-functions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ms-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  isShowNewPassword: boolean;
  isShowConfirmPassword: boolean;

  constructor(public _fb: FormBuilder, private toastr: ToastrService,
    public dialogRef: MatDialogRef<ResetPasswordComponent>) { }

  ngOnInit() {
    this.resetPasswordForm = this._fb.group({
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    })
  }

  showNewPassword(event, passwordInputField) {
    event.preventDefault();
    this.isShowNewPassword = !this.isShowNewPassword;
    passwordInputField.type = passwordInputField.type === 'password' ? 'text' : 'password';
  }

  showConfirmPassword(event, passwordInputField) {
    event.preventDefault();
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
    passwordInputField.type = passwordInputField.type === 'password' ? 'text' : 'password';
  }

  onFormSubmit(form) {
    if (form.valid) {
      if (form.value.newPassword === form.value.confirmPassword) {
        this.dialogRef.close(form.value)
      } else {
        this.toastr.info('New Password Should be equal to Confirm Password')
      }
    } else {
      validateAllFormFields(form)
    }
  }

}
