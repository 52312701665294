import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Event,Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './service/auth-service/auth.service';
import { Object } from 'core-js';
@Component({
   selector: 'app-ecclesia',
   template: `<router-outlet></router-outlet>`,
   encapsulation: ViewEncapsulation.None
})

export class EcclesiaAppComponent implements OnInit {

   currentUrl: any;
   initialURLs = ['', 'login'];

   constructor(translate: TranslateService,
       public router: Router, 
       public location: Location,
       public conf:AuthService) {

      const currentURLStored = localStorage.getItem('CurrentUrl');
       const locationArray = location.path().split('/');
     

     if ( currentURLStored && currentURLStored !== 'null' && currentURLStored !== '/' && currentURLStored !== '/login') {
         let baseurl = currentURLStored.split('?')[0];
         let paramString = currentURLStored.split('?')[1];
         let obj = {}
         if(paramString) {
            let params_arr = paramString.split('&');
            
            for (let i = 0; i < params_arr.length; i++) {
               let pair = params_arr[i].split('=');
               obj[pair[0]] = pair[1]
            } 

         }
         if (locationArray[1] === undefined) {

            obj ? router.navigate([baseurl],{queryParams:obj}):  router.navigate([currentURLStored]);
         
         } else if (locationArray[1] === 'login') {
            
            obj ? router.navigate([baseurl],{queryParams:obj}):  router.navigate([currentURLStored]);
            
         }
      }

      router.events
         .filter(event => event instanceof NavigationEnd)
         .subscribe(e => {

            this.currentUrl = e['url'];
            const urlArray = this.currentUrl.split('/');

            if ( (urlArray[1] === 'login' || urlArray[1] === 'error' ) && urlArray[2] !== undefined) {
               if (urlArray[2] !== 'alreadyLoggedIn') {
                  localStorage.setItem('CurrentUrl', '/login');
                 
               }
            } else {

               if ( urlArray[1] !== 'error' ) {
                  localStorage.setItem('CurrentUrl', this.currentUrl);
               }
               
            }
         });
      
      translate.addLangs(['en', 'fr', 'he', 'ru', 'ar', 'zh', 'de', 'es', 'ja', 'ko', 'it', 'hu']);
      translate.setDefaultLang('en');
      // console.log("loggedUserDetailinapp", JSON.parse(localStorage.getItem('userProfile')).tokenExpiredDateTime)
      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
   }

   ngOnInit() {
      if (localStorage.getItem('userProfile')) {
         if ((new Date(atob(JSON.parse(localStorage.getItem('userProfile')).tokenExpiredDateTime)) <= new Date())) {
            sessionStorage.setItem('tokenExpired', 'true');
            this.router.navigate(['/login']);
         }
      }
   }
}
