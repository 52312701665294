import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirstLetter'
   
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

    transform(value: string): string {
        const prefix = [
            'Mr.',
            'Ms.',
            'Mrs.',
            'Miss.',
            'Master.',
            'Baby.',
            'Dr.',
            'Rev.',
            'Pastor.',
            'Bishop.',
            'Rt. Rev.',
            'Capt.',
            'Col.',
            'Lt. Col.',
            'Maj.',
            'Cdr.',
            'Chief Justice.',
            'Brig.',
            'Gp. Capt.',
            'Adv.',
            'Bro.',
            'Sis.'
        ]
        prefix.forEach(data => {
            if (value.startsWith(data)) {
                const remainingValue = value.substr(data.length)
                // console.log(remainingValue.charAt(0).toUpperCase() + value.slice(1))
                if (remainingValue[0] === remainingValue[0].toLowerCase()) {
                    value = data + remainingValue[0].toUpperCase() + remainingValue.slice(1);
                } else {
                    value = data + remainingValue[0].toUpperCase() + remainingValue.slice(1);
                }
            }
            else {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }
        })
        return value
    }
}




