import { ConvertDateTimeFromUTC } from '../shared/utils/common-functions';
import { MemberSubscription, Subscription } from './MemberSubscriptionPage';

export class AllTypeJSonModal {
    deserialize(input: any) {
        const Obj = {
            // churchId: input.churchId,
            name: input.name,
            description: input.description,
            order: input.order,
            active: input.active,
            id: input.id,
            linkToHarvest: input.linkToHarvest,
            default: input.default
        }
        return Obj;
    }
}

export class SubscriptionTypeJSON {
    deserialize(input: any) {
        let nameArray = [];
        let fullNameWithSpace = '';

        nameArray = input.name.match(/[A-Z][a-z-\'\/]+/g)

        if (nameArray && nameArray.length > 0) {
            nameArray.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== nameArray.length - 1) ? ' ' : '');
            });
        } else {
            fullNameWithSpace = input.name;
        }

        const Obj = {
            // churchId: input.churchId,
            name: fullNameWithSpace,
            description: input.description,
            order: input.order,
            active: input.active,
            id: input.id
        }
        return Obj;
    }
}

export class GetSubscriptionJson {
    deserialize(input: any, subscriptionType: any) {
        const globalTableData = {};
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (input && input.subscription && input.subscription.length > 0) {

            globalTableData['Id'] = input.id;
            globalTableData['CoverId'] = input.coverId;
            globalTableData['memberId'] = input.memberId;
            globalTableData['TransactionNum'] = input.transactionNumber;
            globalTableData['OfferingId'] = input.offeringTypeId;
            globalTableData['PaymentId'] = input.paymentTypeId;
            globalTableData['SMS'] = null;
            globalTableData['Email'] = input.email ? input.email : null;
            globalTableData['Name'] = this.getName(input);
            globalTableData['SubscriptionNo'] = input.coverNumber;
            // Subscription taken for the Month and Year
            globalTableData['Date'] = month[input.monthOfSubscription - 1] + ', ' + input.yearOfSubscription;
            // globalTableData['Date'] = ConvertDateTimeFromUTC(month[input.monthOfSubscription - 1] + ', ' + input.yearOfSubscription, 'MM-YYYY');
            let distinctSubscriptions = this.getDistinctSubscriptionWithTotalAmount(input.subscription);

            distinctSubscriptions.forEach((sub, i) => {
                for (const subType of subscriptionType) {
                    if (subType.id === sub.subscriptionTypeId) {
                        globalTableData[subType.name] = sub.amount;
                    } else {
                        if (!globalTableData[subType.name]) {
                            globalTableData[subType.name] = '-';
                        }
                    }
                }
            });
        }
        // Transaction Date
        globalTableData['Transaction Date'] = ConvertDateTimeFromUTC(input.subscriptionDate, 'DD-MM-YYYY');
        globalTableData['Notes'] = input.notes;
        globalTableData['Total'] = input.totalAmount;
        return globalTableData;
    }

    getDistinctSubscriptionWithTotalAmount(subscriptions: Subscription[]): Subscription[] {
        let distinctSubscriptions: Subscription[] = [];

        let maps = new Map<number, number>();
        for (const { subscriptionTypeId, amount } of subscriptions)
            maps.set(subscriptionTypeId, (maps.get(subscriptionTypeId) || 0) + amount);

        maps.forEach((value, key) => {
            let sub: Subscription = {
                amount: value,
                subscriptionTypeId: key,
                subscriptionTypeName: ''
            };

            distinctSubscriptions.push(sub)
        });

        return distinctSubscriptions;
    }

    getName(memberSubscription: MemberSubscription): string {
        const splitData = memberSubscription.firstName ? memberSubscription.firstName.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = ''

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        return (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace;
    }

    /*     deserialize(input: any, subscriptionType: any) {
    
            const globalTableData = {};
    
            if (input && input.subscription && input.subscription.length > 0) {
    
                const splitData = input.firstName ? input.firstName.split('.') : []
                let fullNameWithSpace = '';
                let nameArray = ''
    
                if (splitData.length > 1) {
                    nameArray = splitData[0];
                    delete splitData[0]
                    splitData.forEach((name, i) => {
                        fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
                    });
                } else if (splitData.length > 0 && splitData.length === 1) {
                    fullNameWithSpace = splitData[0]
                }
    
                globalTableData['Cover No.'] = input.coverNumber;
                globalTableData['Name'] = (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace;
                globalTableData['Date'] = ConvertDateTimeFromUTC(input.subscriptionDate, 'YYYY-MM-DD');
    
                input.subscription.forEach((sub, i) => {
                    for (const subType of subscriptionType) {
                        if (subType.id === sub.subscriptionTypeId) {
                            globalTableData[subType.name] = sub.amount;
                        } else {
                            if (!globalTableData[subType.name]) {
                                globalTableData[subType.name] = '-';
                            }
                        }
                    }
                });
            }
            globalTableData['Notes'] = input.notes;
            globalTableData['Total'] = input.totalAmount;
            return globalTableData;
        } */
}
export class GetSubscriptionExport {
    deserialize(input: any, subscriptionType: any) {
        const globalTableData = {};
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (input && input.subscription && input.subscription.length > 0) {

            // globalTableData['Id'] = input.id;
            // globalTableData['CoverId'] = input.coverId;
            // globalTableData['memberId'] = input.memberId;
            // globalTableData['TransactionNum'] = input.transactionNumber;
            // globalTableData['OfferingId'] = input.offeringTypeId;
            // globalTableData['PaymentId'] = input.paymentTypeId;
            // globalTableData['SMS'] = null;
            // globalTableData['Email'] = input.email ? input.email : null;
            globalTableData['Name'] = this.getName(input);
            globalTableData['SubscriptionNo'] = input.coverNumber;
            // Subscription taken for the Month and Year
            globalTableData['Date'] = month[input.monthOfSubscription - 1] + ', ' + input.yearOfSubscription;
            // globalTableData['Date'] = ConvertDateTimeFromUTC(month[input.monthOfSubscription - 1] + ', ' + input.yearOfSubscription, 'MM-YYYY');
            let distinctSubscriptions = this.getDistinctSubscriptionWithTotalAmount(input.subscription);

            distinctSubscriptions.forEach((sub, i) => {
                for (const subType of subscriptionType) {
                    if (subType.id === sub.subscriptionTypeId) {
                        globalTableData[subType.name] = sub.amount;
                    } else {
                        if (!globalTableData[subType.name]) {
                            globalTableData[subType.name] = '-';
                        }
                    }
                }
            });
        }
        // Transaction Date
        globalTableData['Transaction Date'] = ConvertDateTimeFromUTC(input.subscriptionDate, 'DD-MM-YYYY');
        globalTableData['Notes'] = input.notes;
        globalTableData['Total'] = input.totalAmount;
        return globalTableData;
    }

    getDistinctSubscriptionWithTotalAmount(subscriptions: Subscription[]): Subscription[] {
        let distinctSubscriptions: Subscription[] = [];

        let maps = new Map<number, number>();
        for (const { subscriptionTypeId, amount } of subscriptions)
            maps.set(subscriptionTypeId, (maps.get(subscriptionTypeId) || 0) + amount);

        maps.forEach((value, key) => {
            let sub: Subscription = {
                amount: value,
                subscriptionTypeId: key,
                subscriptionTypeName: ''
            };

            distinctSubscriptions.push(sub)
        });

        return distinctSubscriptions;
    }

    getName(memberSubscription: MemberSubscription): string {
        const splitData = memberSubscription.firstName ? memberSubscription.firstName.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = ''

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        return (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace;
    }

    
}

export class ChurchModalJson {
    deserialize(input: any) {
        const Obj = {
            name: input.name,
            description: input.description,
            logo: input.logo,
            address: input.address,
            city: input.city,
            state: input.state,
            country: input.country,
            contact1: input.contact1,
            dioceseName: input.dioceseName,
            pastorateName: input.pastorateName,
            contactNumber1: input.contactNumber1,
            contactCode1: input.contactCode1,
            contactNumber2: input.contactNumber2,
            contactCode2: input.contactCode2,
            contactMail1: input.contactMail1,
            id: input.id,
            active: input.active,
            code: input.code,
            youtubeChannelLink: input.youtubeChannelLink,
            churchSMSConfigTypeId: input.churchSMSConfigTypeId,
            module: input.module
        }
        return Obj;
    }
}

export class subscriptionMailJSON {

    deserialize(input: any, subscriptionType) {
        const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const globalTableData = {};
        let subscriptionTypes = []
        const splitData = input.firstName ? input.firstName.split('.') : []
        let fullNameWithSpace = '';
        let nameArray = ''

        if (splitData.length > 1) {
            nameArray = splitData[0];
            delete splitData[0]
            splitData.forEach((name, i) => {
                fullNameWithSpace = fullNameWithSpace + name + ((i !== splitData.length - 1) ? ' ' : '');
            });
        } else if (splitData.length > 0 && splitData.length === 1) {
            fullNameWithSpace = splitData[0]
        }

        globalTableData["total"] = input.totalAmount,
            input.subscription.forEach((sub, i) => {
                for (const subType of subscriptionType) {
                    if (subType.id === sub.subscriptionTypeId) {
                        const obj = {}
                        obj['type'] = subType.name;
                        obj["amount"] = sub.amount;
                        subscriptionTypes.push(obj);
                    }
                }
            });
        globalTableData["subscriptionTypes"] = subscriptionTypes;
        globalTableData['Name'] = (nameArray !== '') ? nameArray + '. ' + fullNameWithSpace : fullNameWithSpace;
        globalTableData['Date'] = ConvertDateTimeFromUTC(input.subscriptionDate, 'DD-MM-YYYY');
        globalTableData['MonthOfSubscription'] = month[input.monthOfSubscription - 1];
        globalTableData['YearOfSubscription'] = input.yearOfSubscription;
        globalTableData['SubscriptionNo'] = input.coverNumber;
        globalTableData['memberId'] = input.memberId;
        return globalTableData;
    }
}

export class CarolAreaJson {
    deserialize(input: any) {

        const obj = {
            // churchId: input.churchId,
            carolArea: input.carolArea,
            area: input.area,
            families: input.families,
            id: input.id,
            active: input.active
        }
        return obj;
    }
}

export class GetAllAreaJson {
    deserialize(input: any) {

        let areaArray = []

        if (input && input.area) {
            areaArray = input.area.split(',')
        }

        const obj = {
            // churchId: input.churchId,
            carolAreaName: input.carolAreaName,
            carolArea: input.carolArea,
            area: input.area,
            areaArray: areaArray,
            families: input.families,
            id: input.id,
            active: input.active
        }
        return obj;
    }
}

export class GetCarolJsonModel {
    deserialize(input: any) {

        let areaLocation = []

        if (input && input.areas.length > 0) {
            areaLocation = input.areas.map((list) => new AreaJsonModel().deserialize(list))
        }

        const CarolObj = {
            carolArea: input.carolArea,
            areas: areaLocation
        }
        return CarolObj
    }
}

export class AreaJsonModel {
    deserialize(input: any) {
        const obj = {
            id: input.id,
            area: input.area,
            // churchId: input.churchId,
            active: input.active
        }
        return obj
    }
}

export class UserJsonModal {
    deserialize(input: any) {
        const obj = {
            id: input.id,
            displayName: input.displayName,
            churchName: input.churchName,
            userName: input.userName,
            userRole: input.userRole,
            roleId: input.roleId,
            active: input.active
        }
        return obj
    }
}

export class MemberWithCoverNumberJSonModal {
    deserialize(input: any) {
        const Obj = {
            // churchId: input.churchId,
            coverId: input.coverId,
            coverNumber: input.coverNumber,
            displayName: input.displayName,
            email: input.email,
            memberId: input.memberId,
            memberImage: input.memberImage,
            mobileNumber: input.mobileNumber,
            mobileCode: input.mobileCode,
            subscriptionUniqueKey: input.subscriptionUniqueKey
        }
        return Obj;
    }
}

export class PrintSubscriptionJSonModal {
    deserialize(input: any) {
        const Obj = {
            subscriptionTypeId: input.subscriptionTypeId,
            amount: input.amount,
        }
        return Obj;
    }
}

export class MemberSubscriptionByMonthJson {
    deserialize(input: any) {

        const obj = {
            // churchId: input.churchId,
            subscriptionTypeId: input.subscriptionTypeId,
            amount: input.amount,
        }
        return obj;
    }
}
export class HobbiesAndMinistrialInterestJson {
    deserialize(input: any) {
        const obj = {
            name: input.name,
            description: input.description,
            active: input.active,
            order: input.order,
            id: input.id
        }
        return obj;
    }
}

