import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FamilyService } from 'app/service/auth-service/family.service';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { validateAllFormFields } from 'app/shared/utils/common-functions';
import { ToastrService } from 'ngx-toastr';
import { AddMemberComponent } from '../add-member/add-member.component';
import { addMemberJson } from '../add-member/add-member.modal';

@Component({
  selector: 'ms-transfer-member',
  templateUrl: './transfer-member.component.html',
  styleUrls: ['./transfer-member.component.scss']
})
export class TransferMemberComponent implements OnInit {
  data: string;
  transferMemberForm: FormGroup;
  memberDetails: any;
  usermanagelist = [];
  transferReason = [{
    name: "New Family",
  },
  {
    name: "Existing Family"
  }];
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddMemberComponent>, private route: ActivatedRoute,
    private familyService: FamilyService, private filterTextPipe: FilterTextPipe, private toastr: ToastrService) { }


  ngOnInit() {
    this.transferMemberForm = this.fb.group({
      selectedReason: [this.transferReason[1], [Validators.required]],
      familyHeadName: ['', Validators.required],
      id: 0

    });
  }
  searchFamilyFilterForm(sendObj) {

    const columnSearch = {
      'offset': 0,
      'noOfRows': 10,
      'familySearch': this.transferMemberForm.value.familyHeadName.trim(),
      'columnName': 'Name',
      'active': true,
    }
    Object.assign(columnSearch, sendObj)
    this.familyService.searchFamilyByColums(columnSearch).subscribe(
      data => {
        if (data && data.model.length > 0) {
          const dataList = data.model.filter(item => item.id !== this.memberDetails.member.familyId)
          this.usermanagelist = dataList.map((list) => {
            return new addMemberJson().deserialize(list)
          });
        } else {
          this.usermanagelist = [];

          this.toastr.error('Please select the correct Family Head')
        }
      }, err => {
        console.log('err', err);

        this.usermanagelist = []
        let errorString = 'Something went wrong while searching family List';

        if (err && err.error && err.error.length > 0 && err.error[0] && err.error[0].errorMessage) {
          errorString = err.error[0].errorMessage;
        }
        this.toastr.error(errorString)
      });
  }

  onSelectingReason() {
    if (this.transferMemberForm.value.selectedReason.name === 'Existing Family') {
      this.transferMemberForm.get('selectedReason').setValidators([Validators.required]);
      this.transferMemberForm.get('familyHeadName').setValidators([Validators.required]);

    } else {
      this.transferMemberForm.get('familyHeadName').clearValidators();
      this.transferMemberForm.get('selectedReason').setValidators([Validators.required]);

    }
  }

  selectMember(member) {
    this.transferMemberForm.patchValue({
      familyHeadName: member.name,
      id: member.familyId
    })
  }

  onTransferMember(form) {
    form.value.selectedReason.name === 'Existing Family' && form.value.familyHeadName == "" ? form.controls['familyHeadName'].setErrors({ 'incorrect': true }) : form.controls['familyHeadName'].setErrors(null)
    if (form.valid) {
      let sendObj = {};
      if (this.transferMemberForm.value.selectedReason.name === 'Existing Family') {
        sendObj = {
          reason: form.value.selectedReason.name,
          familyHeadName: form.value.familyHeadName,
          familyId: form.value.id
        }
      }
      else {
        sendObj = {
          reason: form.value.selectedReason.name,
        }
      }
      this.dialogRef.close(sendObj);
    } else {
      validateAllFormFields(form)
    }
  }
}
