import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getPaymentTypes(isactive?: boolean): Observable<any> {
    if (isactive) {
    return this.http.get<any>(this.authService._getAllPaymentType + '?active=' + isactive) ;
    } else {
      return this.http.get<any>(this.authService._getAllPaymentType);
    }
  }

  public getPaymentTypeById(data): Observable<any> {
    return this.http.get<any>(this.authService._getPaymentTypeById + '?id=' + data.id);
  }

  public addPaymentType(data) {
    return this.http.post<any>(this.authService._addPaymentType, data);
  }

  public updatePaymentType(data) {
    return this.http.put(this.authService._updatePaymentType, data);
  }

  public paymentUpdateSequence(data) {
    return this.http.put<any>(this.authService._paymentUpdateSequence, data);
  }

  public deletePaymentType(deleteId): Observable<any> {
    return this.http.delete(this.authService._deletePaymentTypeById + '?id=' + deleteId);
  }

  public deactivPaymentType(deactivateId): Observable<any> {
    return this.http.put(this.authService._deactivatePaymentTypeById + '?id=' + deactivateId, '')
  }

  public ActivePaymentTypeById(Id) {
    return this.http.put(this.authService._ActivePaymentTypeById + '?id=' + Id, '');
  }
}
