import { Directive, NgModule, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[acceptOnlyAlphabets]'
})
export class AcceptOnlyAlphabetsDirective {

    regexStr = '^[a-zA-Z ]*$';

    constructor(private el: ElementRef) { }


    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        this.validateFields(event);
    }

    validateFields(event) {
        setTimeout(() => {
            this.el.nativeElement.value = this.el.nativeElement.value.replace(/[0-9/ ]/g, ' ').replace(/\s/g, ' ');
        }, 100)
    }
}

@NgModule({
    declarations: [AcceptOnlyAlphabetsDirective],
    exports: [AcceptOnlyAlphabetsDirective]
})

export class AcceptOnlyAlphabetsDirectiveModule { }
