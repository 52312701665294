import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MemberService } from 'app/service/auth-service/members.service';
import { ConvertDateTimeFromUTC, validateAllFormFields } from 'app/shared/utils/common-functions';
import { MemberJSON } from 'app/member/member.modal';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/shared/utils/date.adapter';

@Component({
  selector: 'app-ms-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class DeleteDialogComponent implements OnInit {
  isChangeFamilyHead = false;
  data: string;
  memberDetails: any;
  deactivateForm: FormGroup;
  selectFamilyHeadArray = [];
  maxDate = new Date();
  reasons = [
    { name: "Dead" },
    {
      name: "Transfer to New Church",
      placeholderText: "Enter New Church Details",
      errorText: "You must include new church details"
    }, {
      name: "Disciplinary Action",
      placeholderText: "Enter Additional Reasons",
      errorText: "You must include additional reasons"
    }, {
      name: "Others",
      placeholderText: "Enter Additional Reasons",
      errorText: "You must include additional reasons"
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    public fb: FormBuilder,
    private memberService: MemberService,
  ) { }

  ngOnInit() {
    this.deactivateForm = this.fb.group({
      additionalReason: [''],
      familyHead: [''],
      selectedReason: ['', [Validators.required]],
      deathDate: [''],
      reason: ''
    });

    if (this.memberDetails && this.memberDetails.member && this.memberDetails.member.familyHead) {
      this.getMemberByMemberId(this.memberDetails.member.familyId);
    }
  }

  getMemberByMemberId(id) {
    this.memberService.getMemberByFamily(id).subscribe(data => {
      this.selectFamilyHeadArray = data.map(list => new MemberJSON().deserialize(list)).filter(obj => {
        if (obj.id !== this.memberDetails.member.id) {
          return obj;
        }
      });
      if (this.selectFamilyHeadArray && this.selectFamilyHeadArray.length>0) {
        this.deactivateForm.get('familyHead').setValidators([Validators.required]);
        this.isChangeFamilyHead = true;
      }
    });
  }

  yes() {
    if (this.memberDetails && this.memberDetails.header && this.memberDetails.header === 'Deactivate Member') {
      if (this.deactivateForm.valid) {
        let detailedReason;
        if (this.deactivateForm.value.selectedReason.name === 'Transfer to New Church' || this.deactivateForm.value.selectedReason.name === 'Disciplinary Action' || this.deactivateForm.value.selectedReason.name === 'Others') {
          detailedReason = {
            reason: this.deactivateForm.value.selectedReason.name,
            additionalReason: this.deactivateForm.value.additionalReason
          };
          if (this.isChangeFamilyHead) {
            detailedReason['newFamilyHead'] = this.deactivateForm.value.familyHead;
          }
        } else if (this.deactivateForm.value.selectedReason.name === 'Dead') {
          detailedReason = {
            reason: this.deactivateForm.value.selectedReason.name,
            deathDate: ConvertDateTimeFromUTC(this.deactivateForm.value.deathDate, 'YYYY/MM/DD')
          };
          if (this.isChangeFamilyHead) {
            detailedReason['newFamilyHead'] = this.deactivateForm.value.familyHead;
          }
        }
        this.deactivateForm.patchValue({ reason: detailedReason });

        if (this.memberDetails.header === 'Deactivate Member') 
          if(this.isChangeFamilyHead)
          {
          const element = {
            res: 'yes',
            value: this.deactivateForm.value,
            isChangeFamilyHead: this.isChangeFamilyHead
          };
          this.dialogRef.close(element);
        } else  if(!this.isChangeFamilyHead)
          {
          const element = {
            res: 'yes',
            value: this.deactivateForm.value,
            isChangeFamilyHead: this.isChangeFamilyHead
          };
          this.dialogRef.close(element);
        }  
        
        else {
          this.dialogRef.close('yes');
        }
      } else {
        validateAllFormFields(this.deactivateForm);
      }
    } else {
      this.dialogRef.close('yes');
    }
  }

  onSelectingReason() {
    const selectedReason = this.deactivateForm.value.selectedReason;
    if (selectedReason.name === 'Dead') {
      this.deactivateForm.get('deathDate').setValidators([Validators.required]);
      this.deactivateForm.get('additionalReason').clearValidators();
      if (this.isChangeFamilyHead) {
        this.deactivateForm.get('familyHead').setValidators([Validators.required]);
      }
    } else {
      this.deactivateForm.get('additionalReason').setValidators([Validators.required]);
      this.deactivateForm.get('deathDate').clearValidators();
      this.deactivateForm.get('familyHead').clearValidators();
    }
    this.deactivateForm.get('deathDate').updateValueAndValidity();
    this.deactivateForm.get('additionalReason').updateValueAndValidity();
    this.deactivateForm.get('familyHead').updateValueAndValidity();
  }
}
