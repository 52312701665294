import { Headers } from '@angular/http';
import { AppConfig } from '../../app-config';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    subURLsNotNeedingAuthHeader = AppConfig.settings.subURLsNotNeedingAuthHeader.URL;
    constructor(private conf: AuthService, private injector: Injector, private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const endURLPart = req.url.split('/').pop();
        const jsonCheck = endURLPart.search(/json/) > -1
        if (!jsonCheck && !this.subURLsNotNeedingAuthHeader.includes(endURLPart)) {
            req = this.setAuthHeaderToRequest(req);
        } else if (!jsonCheck) {
            req = req.clone({
                headers: req.headers.set('Accept', 'application/json')
            });
        }
        return next.handle(req).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
        }, (error) => {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                    if(error.url !== (this.conf._login) && error.url !== (this.conf._validatemfacredentials)) {
                    this.router.navigate(['/login']);
                    }
                }
            }
        });
    }

    setAuthHeaderToRequest(req) {
        const token = this.conf.getTokenId();
        if (req.headers.lazyUpdate && req.headers.lazyUpdate.length) {
            req = req.clone({
                headers: req.headers.set('authorization', 'bearer' + ' ' + token),
            });
        } else {
            req = req.clone({
                headers: req.headers.set('authorization', 'bearer' + ' ' + token)
                    .set('Accept', 'application/json')
            });
        }
        return req;
    }
}