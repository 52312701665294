import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterTextPipe } from './filter-text.pipe';
import { EmailFilterPipe } from './email-filter.pipe';
import { FilterPipe } from './member-filter.pipe';
import { CapitalizeFirstLetterPipe } from './capilizeFirstLetter.pipe';
import { CapitalizeAfterCommaPipe } from './capitalizeLetter.pipe'

@NgModule({
  declarations: [FilterTextPipe, EmailFilterPipe, FilterPipe, CapitalizeFirstLetterPipe, CapitalizeAfterCommaPipe],
  imports: [
    CommonModule
  ],
  exports: [FilterTextPipe, EmailFilterPipe, FilterPipe, CapitalizeFirstLetterPipe, CapitalizeAfterCommaPipe],
  providers: [FilterTextPipe, EmailFilterPipe, FilterPipe, CapitalizeFirstLetterPipe, CapitalizeAfterCommaPipe]
})
export class PipeModule { }