import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    constructor(private authService: AuthService, private http: HttpClient) { }

    public getAllMemberList(active): Observable<any> {
        return this.http.get<any>(this.authService._getAllMembers + '?active=' + active);
    }

    public getMemberByFamily(familyId): Observable<any> {
        return this.http.get<any>(this.authService._getMemberByFamily + '?familyId=' + familyId);
    }

    public getMemberById(Id): Observable<any> {
        return this.http.get<any>(this.authService._getMemberById + '?memberId=' + Id);
    }

    public getSpouseById(Id): Observable<any> {
        return this.http.get<any>(this.authService._getSpouse + '?memberId=' + Id);
    }


    public searchByMember(data): Observable<any> {
        return this.http.post<any>(this.authService._searchByMember, data);
    }
    public searchByNonMember(data): Observable<any> {
        return this.http.post<any>(this.authService._searchByNonMember, data);
    }

    public addMember(data) {
        return this.http.post(this.authService._addMembers, data);
    }

    public getMemberListByPaging(data, sortReqUrl) {
        return this.http.post<any>(this.authService._getMemberListByPaging + sortReqUrl, data);
    }

    public updateMembers(data) {
        return this.http.put(this.authService._updateMember, data);
    }
    public removeMembers(id) {
        return this.http.post(this.authService._removeMember + '?memberId=' + id, '');
    }
    public deleteMemberById(Id) {
        return this.http.delete(this.authService._deleteMemberById + '?id=' + Id);
    }

    public ActiveMemberById(Id) {
        return this.http.put(this.authService._activeMemberById + '?id=' + Id, '');
    }

    public deactivateMemberById(obj) {
        return this.http.put(this.authService._deactivateMemberById, obj);
    }
    public transferMember(obj) {
        return this.http.put(this.authService._transferMember, obj);
    }
    public uploadMember(file): Observable<any> {
        return this.http.post(this.authService._uploadMember,
            file, this.authService.getOptionsWithFormDataAsRequestFormat());
    }
    public uploadMemberImage(file): Observable<any> {
        return this.http.post(this.authService._uploadMemberImage,
            file, this.authService.getOptionsWithFormDataAsRequestFormat());
    }

    public importMemberModel(data) {
        // const dateFormat = data.dateFormat
        // const file = data.file;
        // const sendURl = '?delimeter=' + "'" + data.delimeter + "'" +
        //     '&dateFormat=' + dateFormat;
        return this.http.post(this.authService._importMember, data);
    }

    public getAllMemberOnlineRegistry(data): Observable<any> {
        return this.http.post<any>(this.authService._getAllMemberOnlineRegistry, data);
    }

    public onlineRegistryMemberApproval(data): Observable<any> {
        return this.http.post<any>(this.authService._onlineRegistryMemberApproval, data);
    }

    //Member Role

    public getAllMemberRole(active): Observable<any> {
        return this.http.get<any>(this.authService._getAllMemberRole + '?active=' + active);
    }

    public getMemberRoleById(id): Observable<any> {
        return this.http.get<any>(this.authService._getMemberRoleById + '?id=' + id);
    }

    public getImportmemberExe(active): Observable<any>{
        return this.http.get<any>(this.authService._allExeFile + '?active=' + active);
    }   
    
    
    //Member Events

    public getMemberEvents(data, url) {
        return this.http.post<any>(this.authService.getMemberTableEvents + url, data);

    }

    public quickSaveFamily(data) {
        return this.http.post<any>(this.authService._quicksaveFamily, data);
    }

//MEMBER EXEFILE
// public getMemberimportExe(active)
}
// '&HeaderData=' + headerData +