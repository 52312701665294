import { Component, OnInit } from '@angular/core';
// import { Location } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CarolAreaService } from 'app/service/auth-service/carol-area.service';
import { CarolAreaJson } from 'app/subscription/subscription.modal';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { validateAllFormFields } from 'app/shared/utils/common-functions';
import { ToastrService } from 'ngx-toastr';
import { FamilyService } from 'app/service/auth-service/family.service';
import { ActivatedRoute } from '@angular/router';
import { FamilyJson } from '../../../member/family-member.modal';

@Component({
	selector: 'app-ms-add-new-card',
	templateUrl: './add-new-card.component.html',
	styleUrls: ['./add-new-card.component.scss']
})
export class AddNewCardComponent implements OnInit {
	addCarolAreaForm: FormGroup;
	title: string;
	addAreaForm: FormGroup;
	addGroupForm: FormGroup;
	updateFamilyHeadForm: FormGroup;
	addAuctionTypeForm: FormGroup;

	getAllCarolAreaList = []
	isListOfCarolAreaLocation = []
	isClickedLocation: boolean;
	getFamilyMembers = [];
	filterFamilyHead = [];
	getUserFamilyId;
	newFamilyHead;
	previousFamilyHead;

	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, public dialogRef: MatDialogRef<AddNewCardComponent>,
		private carolAreaService: CarolAreaService, private familyService: FamilyService, private filterService: FilterTextPipe, private toastr: ToastrService,) { }

	ngOnInit() {
		if (this.title === 'updateFamilyHead') {
			// this.route.queryParams.subscribe((family) => {
			// 	if (family) {
			// 		if (family.id === '0') {
			// 		} else {
			// 			this.getUserFamilyId = parseInt(family.id, 0);
			// 			console.log(this.getUserFamilyId)
			// 		}
			// 	}
			// })
			this.updateFamilyHead();
		} else if (this.title === 'Carol Area') {
			this.getAllCarolArea();
		}


		this.addCarolAreaForm = this.formBuilder.group({
			carolAreaName: [null, [Validators.required]],
			location: ['', [Validators.required]],
			id: [0]
		})

		this.addAreaForm = this.formBuilder.group({
			location: ['', [Validators.required]],
		})

		this.addGroupForm = this.formBuilder.group({
			name: [null,[Validators.required]],
			description: [null]
		})
		this.updateFamilyHeadForm = this.formBuilder.group({
			familyMembers: [null, [Validators.required]],
		})
		this.addAuctionTypeForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			description: [null],
		})
	}

	getAllCarolArea() {
		this.carolAreaService.getAllCarolAreaList().subscribe(
			carol => {
				if (carol && carol.length > 0) {
					const deserialize = carol.map((carolArea) => new CarolAreaJson().deserialize(carolArea))
					this.getAllCarolAreaList = deserialize.filter((list) => list.carolArea === null)
				}
			}, err => {
				console.log('error', err)
				this.toastr.error('Something went wrong while getting the Subscriptions')
			})
	}

	searchForLocation() {
		this.isClickedLocation = false;
		this.isListOfCarolAreaLocation = this.filterService.transform(this.getAllCarolAreaList, this.addCarolAreaForm.value.location, 'area')
	}

	selectLocationArea(area) {
		this.isClickedLocation = true;
		this.addCarolAreaForm.patchValue({
			location: area.area,
			id: area.id
		})
	}

	onFormSubmit(form) {
		if (form.valid) {
			this.dialogRef.close(form.value)
		} else {
			validateAllFormFields(form)
		}
	}

	updateFamilyHead() {
		const familyId = window.location.href.split('=')[1];
		this.familyService.getFamilybyIdView(familyId,true).subscribe(data => {
			this.getFamilyMembers = data.members.map((list) => new FamilyJson().deserialize(list));
			this.getFamilyMembers.forEach(element => {
				if (element.familyHead !== true) {
					this.filterFamilyHead.push(element);
				} else {
					this.previousFamilyHead = element.id;
				}
			})
		})
	}

	updateHead(updateFamilyHeadForm) {
		const sendObj = {
			"oldMemberId": this.previousFamilyHead,
			"newMemberId": this.updateFamilyHeadForm.controls['familyMembers'].value,
			"inActiveReason": ''
		}
		this.familyService.onFamilyHeadChange(sendObj).subscribe(data => {
			this.toastr.success(data + '');
			this.dialogRef.close(updateFamilyHeadForm.value);
		}, error => {
			this.toastr.error("Something went wrong.please try again")
			this.dialogRef.close(updateFamilyHeadForm.value)
		})
	}
}
