import * as moment from 'moment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

export function ConvertDateTimeToUTC(date) {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
}

export function ConvertDateTimeFromUTC(date, format) {
  return moment.utc(date).local().format(format);
}

export function CreateTilteWithSpace(content: string) {
  return content.match(/[A-Z][a-z]+/g)
}

export function validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    } else if (control instanceof FormArray) {
      validateFormArray(control);
    }
  });
}

export function validateFormArray(formArray) {
  for (const control of formArray.controls) {
    if (control instanceof FormGroup) {
      validateAllFormFields(control);
    } else if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormArray) {
      validateFormArray(control);
    }
  }
}

export function customValidateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      customValidateAllFormFields(control);
    } else if (control instanceof FormArray) {
      validateFormArray(control);
    }
  });
}

export function getFileURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function noWhitespaceValidator(control: FormControl) {
  if(control.value){
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}
