export class addMemberJson {
    deserialize(input: any) {
        const Obj = {
            name : input.name,
            formNo : input.formNumber,
            familyId : input.id,
            image : input.familyImage
        }
        return Obj
    }
}