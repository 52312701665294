import { Component, OnInit, Input, ViewChild, ElementRef, Inject, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDatepicker, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { validateAllFormFields, ConvertDateTimeFromUTC } from 'app/shared/utils/common-functions';
import { CoverService } from 'app/service/auth-service/cover.service';
import { PaymentTypeService } from 'app/service/auth-service/payment-type.service';
import { Moment } from 'moment';
import { FilterTextPipe } from 'app/shared/pipes/filter-text.pipe';
import { ToastrService } from 'ngx-toastr';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/shared/utils/date.adapter';
import { GetCoverList } from 'app/harvest-fund/harvest.modal';
import { MemberWithCoverNumberJSonModal } from '../../../subscription/subscription.modal';
import { ChurchConfigService } from 'app/service/auth-service/church-config.service';
import { ChurchService } from 'app/service/auth-service/church.service';
import { AuctionTypeService } from 'app/service/auth-service/auction-type.service';
import { AllTypeJSonModal } from 'app/subscription/subscription.modal';
import { AnnouncementService } from 'app/service/auth-service/announcement.service';
import { AnnouncementJsonList } from 'app/announcement/announcement.modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
// import { CropperSettings, ImageCropperComponent } from 'ng2-img-cropper';


@Component({
	selector: 'app-ms-add-new-client',
	templateUrl: './add-new-client.component.html',
	styleUrls: ['./add-new-client.component.scss'],
	providers: [
		{
			provide: DateAdapter, useClass: AppDateAdapter
		},
		{
			provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
		}
	]
})
export class AddNewClientComponent implements OnInit {

	@Input() max: any;
	// fromMinDate = new Date();
	// toMinDate;
	current_environment: String = environment.current_environment;
	// cropperSettings: CropperSettings;
	imageData: any;
	@ViewChild('cropper')
	private _cropper: ImageCropperComponent;

	// @ViewChild("cropper")
	// private _cropper: ImageCropperComponent;
	addNewUserForm: FormGroup;
	addAuctionForm: FormGroup;
	addPayForm: FormGroup;
	announcementForm: FormGroup;
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
	getCoverDataList = [];
	getPaymentDataList = [];
	auctionTypeList = [];
	selectedUsers;
	searchBy = [
		{ value: 'coverNumber', viewValue: 'Subscription No' },
		{ value: 'mobileNumber', viewValue: 'Mobile No' },
		{ value: 'displayName', viewValue: 'Name' }
	];
	selectedSearchValue = { value: 'coverNumber', viewValue: 'Subscription No' };
	coverNumber: any;
	memberName: any;
	// CoverNumber List
	churchId;
	isClickedCoverNum: boolean;
	isClickedOption: boolean = false;
	selectedImageFormData: any
	isEmptyOption: boolean = true;
	imageChangedEvent: any = '';
	isListOfCoverNum = [];
	getMemberBySearchList = [];
	coverNumberWithMemberDetails = [];
	announcementFile = [];
	announcementListById;
	selected = 'domain';
	announcementTitle;
	announcementImage: string;
	isLoading: boolean;
	//minDate: string;
	isSelectedAdd: any;
	// DOCUMENT: InjectionToken<Document>;
	minDate: Date;
	constructor(private formBuilder: FormBuilder, public coverService: CoverService, public dialogRef: MatDialogRef<AddNewClientComponent>,
		public paymentService: PaymentTypeService, private filterService: FilterTextPipe, private toastr: ToastrService,
		private churchConfigService: ChurchConfigService, private churchService: ChurchService, private auctionTypeService: AuctionTypeService,
		private announcementService: AnnouncementService, @Inject(DOCUMENT) private document: Document, private datePipe: DatePipe) {
		// this.cropperSettings = new CropperSettings();
		// this.cropperSettings.croppedWidth = 100;
		// this.cropperSettings.croppedHeight = 100;
		// this.cropperSettings.canvasWidth = 600;
		// this.cropperSettings.canvasHeight = 400;
		// this.cropperSettings.noFileInput = true;
		// this.imageData = {};
	}

	ngOnInit() {
		this.getCoverList()
		this.getPaymentList()
		this.createForm()
		// this.getMyChurch()
		this.getMyChurchConfig()
		this.getAuctionTypeDetails()
		this.getCoversByChurch()
		if (this.isSelectedAdd !== 0) {
			this.getAnnouncementListById()
			this.announcementTitle = "Update Announcement"
		}
		else {
			this.announcementTitle = "Add Announcement"
		}
		if (this.isSelectedAdd && this.coverNumber.cover !== '' && this.coverNumber.cover !== null) {
			this.addPayForm.patchValue({
				coverNumber: this.coverNumber.cover,
				memberName: this.coverNumber.memberName
			})
		}
		this.addAuctionForm.get('slipNo').disable()
		//this.toMinDate = this.announcementForm.value.startDate

		const today = new Date();
		this.minDate = new Date();
	}


	createForm() {
		this.addNewUserForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			emailAddress: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
			phoneNumber: ['', [Validators.required]],
			location: ['', [Validators.required]],
		})

		this.addAuctionForm = this.formBuilder.group({
			coverNumber: ['', [Validators.required]],
			date: [ConvertDateTimeFromUTC(new Date(), 'YYYY-MM-DD'), Validators.required],
			slipNo: [''],
			auctionType: ['', [Validators.required]],
			itemName: ['', [Validators.required]],
			itemAmount: [null, [Validators.required, Validators.min(1)]],
			onSelectSearch: [this.searchBy[0].viewValue]
		})

		this.addPayForm = this.formBuilder.group({
			coverNumber: [''],
			memberName: [''],
			date: [ConvertDateTimeFromUTC(new Date(), 'YYYY-MM-DD'), Validators.required],
			amount: [null, [Validators.required, Validators.min(1)]],
			paymentType: ['Cash', Validators.required],
			transactionNumber: [{ value: '', disabled: true }],
			paymentArray: [],
			onSelectSearch: [this.searchBy[0].viewValue]
		})
		this.announcementForm = this.formBuilder.group({
			image: [''],
			title: ['', [Validators.required]],
			description: ['', [Validators.required]],
			startDate: ['', Validators.required],
			endDate: ['', Validators.required],
			fileSize: [''],
			announcementId: [null],
			isImageCropped: false,
			isImageSelected: false,
		})

		// this.addPayForm.get('paymentType').valueChanges.subscribe(data => {
		// 	if (data != 'Cash') {
		// 		this.addPayForm.get('transactionNumber').setValidators([Validators.required]);
		// 		this.addPayForm.get('transactionNumber').enable();
		// 	} else {

		// 		this.addPayForm.get('transactionNumber').clearValidators();
		// 		this.addPayForm.get('transactionNumber').disable();

		// 	}
		// })
	}
	onSelectingPaymentType(payment) {
		// this.selectFormArray = this.selectfieldForm.value.paymentName.toLowerCase();
		if (payment.toLowerCase() != 'cash') {
			this.addPayForm.get('transactionNumber').setValidators([Validators.required]);
			this.addPayForm.get('transactionNumber').enable();
		} else {
			this.addPayForm.get('transactionNumber').clearValidators();
			this.addPayForm.get('transactionNumber').disable();
			this.addPayForm.get('transactionNumber').reset();
		}
	}
	_openDatepickerOnClick(datepicker: MatDatepicker<Moment>) {

		if (!datepicker.opened) {
			datepicker.open();
		}
		//this.announcementForm.value.startDate != "" ? this.announcementForm.get('endDate').enable() : this.announcementForm.get('endDate').disable();
	}
	openDatepickerOnClick(startDatepicker) {
		if (startDatepicker.opened == true) {
			this.announcementForm.value.startDate != "" ? this.announcementForm.get('endDate').enable() : this.announcementForm.get('endDate').disable();
		}


	}
	_toDatepickerOnClick(datepicker: MatDatepicker<Moment>) {
		if (!datepicker.opened) {
			datepicker.open();
			//this.toMinDate = this.announcementForm.value.startDate

		}
	}
	getCoverList() {
		this.coverService.getAllCoverList(true).subscribe(
			list => {
				if (list && list.length > 0) {
					this.getCoverDataList = list.map((cover) => new GetCoverList().deserialize(cover));
				}
			}, err => {
				console.log('err', err)
				this.toastr.error('Something went wrong while getting the covers')
			})
	}

	getAuctionTypeDetails() {
		this.auctionTypeService.getAllAuctiontype(true).subscribe(
			data => {
				const deserializedData = data.map((offer) => new AllTypeJSonModal().deserialize(offer));
				const sortedData = deserializedData.sort((a, b) => { return a.order - b.order })
				this.auctionTypeList = sortedData
			}, err => {
				console.log('err', err);
				this.toastr.error('Something went wrong while get Auction List. please refresh the page')
			});
	}

	getPaymentList() {
		this.paymentService.getPaymentTypes(true).subscribe(
			pay => {
				if (pay && pay.length > 0) {
					this.getPaymentDataList = pay.filter(list => (list.active === true));
					this.getPaymentDataList = this.getPaymentDataList.sort((a, b) => { return a.order - b.order })
					this.addPayForm.patchValue({
						paymentArray: this.getPaymentDataList
					})
				}
			}, err => {
				console.log('err', err)
				this.toastr.error('Something went wrong while getting the payment types')
			})
	}

	searchForCoverNumber(isAddForm: string) {
		this.isClickedCoverNum = false;
		let searchText = '';
		if (isAddForm === 'AddForm') {
			searchText = '' + this.addAuctionForm.value.coverNumber
		} else {
			searchText = '' + this.addPayForm.value.coverNumber
		}

		this.isListOfCoverNum = this.filterService.transform(this.getCoverDataList, searchText, 'coverNumber')
		this.isListOfCoverNum = this.isListOfCoverNum.sort();

		if (this.isListOfCoverNum.length === 0) {
			this.toastr.info('No Subscription Number')
		}
	}

	selectCoverNumber(cover) {
		this.isClickedCoverNum = true;
		if (this.isSelectedAdd) {
			this.addPayForm.patchValue({
				coverNumber: cover
			})
		} else {
			this.addAuctionForm.patchValue({
				coverNumber: cover
			})
		}
	}

	// onFormSubmit method is submit a add new user form.
	onFormSubmit(form) {
		if (form.valid) {
			if (this.addAuctionForm.valid) {
				this.dialogRef.close(this.addAuctionForm.value);
			  }
			this.dialogRef.close(form.value);
		} else {
			validateAllFormFields(form)
		}
	}

	getCoversByChurch() {
		this.coverService.getCoversbyChurchwithMemberdetails(true).subscribe((data) => {
			if (data && data.length > 0) {
				this.getMemberBySearchList = data.map((company) => new MemberWithCoverNumberJSonModal().deserialize(company));
			}
		}, err => {
			console.log(err, 'error')
			this.toastr.error('Something went wrong while getting covers list. Please refresh the Page')
		})
	}

	onSearchingCoverNumber(isAddForm: string, event) {
		if (event.key === 'Backspace') {
			this.isEmptyOption = false
			this.selectedUsers = ''
		}
		else {
			this.isEmptyOption = true
		}
		// this.isClickedMemberField = false;
		let searchingText = '';
		if (isAddForm === 'AddForm') {
			searchingText = '' + this.addAuctionForm.value.coverNumber
		} else {
			searchingText = '' + this.addPayForm.value.coverNumber
		}

		this.coverNumberWithMemberDetails = this.filterService.transform(this.getMemberBySearchList, searchingText, this.selectedSearchValue.value)
		this.coverNumberWithMemberDetails = this.coverNumberWithMemberDetails.sort();

		if (this.selectedSearchValue.value === 'coverNumber') {
			if (this.coverNumberWithMemberDetails.length === 0) {
				this.toastr.info('No Subscription Number')
			}
		}
		// let searchText = this.searchCoverForm.value.memberSearchField;
		// let nameOfSearchField = this.searchCoverForm.value.columnSearch.name;
		// this.isListOfMemberSearch = this.filterService.transform(this.getMemberBySearchList, searchText, nameOfSearchField)
	}

	onSelectSearchBy(element) {
		this.selectedSearchValue = element;

	}

	optionClicked(event: Event, member) {
		this.isClickedOption = true;
		this.isEmptyOption = true
		this.selectedUsers = member.displayName;
	}

	// getMyChurch() {
	// 	this.churchService.getChurch().subscribe(data => {
	// 		this.churchId = data.id
	// 		this.getMyChurchConfig(this.churchId)
	// 	})
	// }
	getMyChurchConfig() {
		this.churchConfigService.getMyChurchConfig().subscribe(data => {
			this.addAuctionForm.patchValue({
				slipNo: data.auctionSlipNumber
			})
			this.addPayForm.patchValue({
				slipNo: data.auctionSlipNumber
			})
		})
	}

	getAnnouncementListById() {
		const image: any = new Image();
		const that = this;
		this.announcementService.getChurchAnnouncementById(this.isSelectedAdd).subscribe((data: any) => {
			this.announcementListById = new AnnouncementJsonList().deserialize(data);
			// this.announcementImage = this.announcementListById.attachment
			this.announcementForm.patchValue({
				image: this.announcementListById.attachment,
				announcementId: this.announcementListById.id,
				title: this.announcementListById.announcement,
				description: this.announcementListById.announcementDescription,
				startDate: ConvertDateTimeFromUTC(this.announcementListById.fromDateTime, 'YYYY-MM-DD'),
				endDate: ConvertDateTimeFromUTC(this.announcementListById.toDateTime, 'YYYY-MM-DD'),
				fileSize: this.announcementListById.fileSize,
				isImageCropped: false
			})
			this.announcementImage = this.announcementListById.attachment

			// const url =  this.document.defaultView.URL.createObjectURL(this.announcementListById.attachment);
			// this.announcementImage = url
			// this.imageData.image = this.announcementListById.attachment
			// this.imageData = this.announcementListById.attachment
			//  image.src = this.imageData

		}, error => {
			console.log(error);
		})
	}


	async getBase64ImageFromUrl(imageUrl) {
		var res = await fetch(imageUrl);
		var blob = await res.blob();

		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.addEventListener("load", function () {
				resolve(reader.result);
			}, false);

			reader.onerror = () => {
				return reject(this);
			};
			reader.readAsDataURL(blob);
		})
	}

	onImageUpload(event: any, form) {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const maxFileSize = 1024 * 1024 * 2;
			if (file.size < maxFileSize) {
				this.imageChangedEvent = event;
				form.patchValue({
					isImageCropped: true
				})
			} else {
				this.toastr.error('File size is exceeded..., File Size Should be 2MB');
			}
		}
	}

	imageCropped(event: ImageCroppedEvent, form) {
		var blob = this.dataURItoBlob(event.base64);
		this.selectedImageFormData = new FormData(document.forms[0]);
		this.selectedImageFormData.append("file", blob);
	}

	dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], { type: mimeString });
	}

	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}

	// readUrl(event: any) {
	// 	const image: any = new Image();
	// 	const maxImageSize = 1024 * 1024 * 2;
	// 	const that = this;
	// 	if (event.target.files && event.target.files[0]) {
	// 		const reader = new FileReader();

	// 		reader.onload = (loadevent: any) => {
	// 			this.announcementForm.patchValue({
	// 				isImageCropped: true
	// 			})
	// 		}
	// 		reader.onloadend = function (loadEvent: any) {
	// 			if ((loadEvent.loaded / maxImageSize) > maxImageSize) {
	// 				console.log('exceeded')
	// 			} else {
	// 				image.src = loadEvent.target.result;
	// 				console.log(that._cropper, "cropper")
	// 				that._cropper.setImage(image);
	// 				console.log(loadEvent.target.result)
	// 			}
	// 		};
	// 		reader.readAsDataURL(event.target.files[0]);
	// 	}

	// }

	//   CropperHide() {
	// 	this.changedProfileImage = false;
	// 	this.selectedPicture.nativeElement.value = '';
	// 	this.data = {};
	//   }

	updateAnnouncement() {
		if (this.announcementForm.valid && ((Date.parse(ConvertDateTimeFromUTC(this.announcementForm.value.startDate, 'YYYY-MM-DD')) <= Date.parse(ConvertDateTimeFromUTC(this.announcementForm.value.endDate, 'YYYY-MM-DD'))))) {
			this.isLoading = true;
			if (this.selectedImageFormData) {
				if(this.current_environment === 'S3'){
				this.announcementService.uploadChurchAnnouncement(this.selectedImageFormData).subscribe((file) => {
					this.announcementForm.patchValue({
						image: file.fileLink,
						fileSize: file.fileSize,
						isImageSelected: true
					})
					this.isLoading = false;
					this.dialogRef.close(this.announcementForm.value)

				}, err => {
					console.log('err', err)
					this.toastr.error('Something went wrong while uploading event Image. Please try again later')
				})}
				else{
					this.announcementService.uploadchurchannouncementimage(this.selectedImageFormData).subscribe((file) => {
						this.announcementForm.patchValue({
							image: file.fileLink,
							fileSize: file.fileSize,
							isImageSelected: true
						})
						this.isLoading = false;
						this.dialogRef.close(this.announcementForm.value)
	
					}, err => {
						console.log('err', err)
						this.toastr.error('Something went wrong while uploading event Image. Please try again later')
					})
				}
			} else {
				this.dialogRef.close(this.announcementForm.value)
			}
		} else {
			if ((Date.parse(ConvertDateTimeFromUTC(this.announcementForm.value.startDate, 'YYYY-MM-DD')) > Date.parse(ConvertDateTimeFromUTC(this.announcementForm.value.endDate, 'YYYY-MM-DD')))) {
				this.toastr.error("ToDate cannot be less than Start Date")
			}
			validateAllFormFields(this.announcementForm)
		}
	}

}
