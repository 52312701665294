import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {GeneAppModule} from './app/app.module';
import {environment} from './environments/environment';


if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(GeneAppModule);
platformBrowserDynamic().bootstrapModule(GeneAppModule).then(ref => {
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;
}).catch(err => console.error(err));



