import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuctionTypeService } from 'app/service/auth-service/auction-type.service';
import { AllTypeJSonModal } from 'app/subscription/subscription.modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ms-import-instructions',
  templateUrl: './import-instructions.component.html',
  styleUrls: ['./import-instructions.component.scss']
})
export class ImportInstructionsComponent implements OnInit {
  instructions;
  displayedColumns = ["auctionTypeId", "auctionName"];
  constructor(public dialogRef: MatDialogRef<ImportInstructionsComponent>, private auctionTypeService: AuctionTypeService, private toastr: ToastrService) { }

  ngOnInit() {
    if (this.instructions.type === 'harvest') {
      this.getAuctionTypeDetails();
    }

  }

  getAuctionTypeDetails() {
    this.instructions['isLoading'] = true;
    this.auctionTypeService.getAllAuctiontype(false).subscribe(
      data => {
        this.instructions['isLoading'] = false;
        this.instructions['autionTypeList'] = data.map((offer) => new AllTypeJSonModal().deserialize(offer));
        // const sortedData = deserializedData.sort((a, b) => { return a.order - b.order })
      }, err => {
        this.instructions['isLoading'] = false;
        console.log('err', err);
        this.toastr.error('Something went wrong while get Auction List. please refresh the page')
      });
  }
}
