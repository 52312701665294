import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CoreService } from '../../service/core/core.service';
import { AuthService } from '../../service/auth-service/auth.service';

@Component({
	selector: 'ms-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss']
})

export class SideBarComponent implements OnInit {

	@Input() menuList: any;
	@Input() verticalMenuStatus: boolean;
	userRoleName: string;
	fullList = [];
	churchLogo: string;

	constructor(public translate: TranslateService, private router: Router, public coreService: CoreService, private authService: AuthService) { }

	ngOnInit() {
		this.userRoleName = this.authService.getUserRole();
		this.fullList = this.menuList.getAll()
		if (this.userRoleName !== 'Admin') {
			this.fullList = this.fullList.filter(obj => obj.name !== 'Announcement' && obj.name !== 'Settings')
		}
		// else {
		// 	this.fullList.filter((val, i) => {
		// 		if (val.name === "Settings") {
		// 			this.fullList.splice(i, 1)
		// 		}
		// 	})
		// }
		let churchLogo = JSON.parse(localStorage.getItem('churchLogo'))
		if (churchLogo) {
		   this.churchLogo = churchLogo
		   console.log(this.churchLogo)
		}
		else {
		   this.churchLogo = 'assets/img/logo-with-grey-bg.svg'
		}
	}

	onClick() {
		const first = location.pathname.split('/')[1];
		if (first === 'horizontal') {
			this.router.navigate(['/horizontal/dashboard']);
		} else {
			this.router.navigate(['/dashboard']);
		}
	}
}
