import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { validateAllFormFields } from 'app/shared/utils/common-functions';

@Component({
  selector: 'ms-edit-ministry',
  templateUrl: './edit-ministry.component.html',
  styleUrls: ['./edit-ministry.component.scss']
})
export class EditMinistryComponent implements OnInit {
 
	data: any;
	customOrderId: number;

	customSettingsForm: FormGroup;
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
	ministryDetails: any;
  constructor(private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<EditMinistryComponent>) { }

    ngOnInit() {
      this.customSettingsForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        description: [''],
        order: ['', [Validators.required]],
        id:['']
      });
  
    

      this.customSettingsForm.patchValue({
      name: this.ministryDetails.name,
      description: this.ministryDetails.description,
      order: this.ministryDetails.order,
      id: this.ministryDetails.id
    });
  }
    onFormSubmit(form) {
      if (form.valid) {
        this.dialogRef.close(form.value);
      } else {
        validateAllFormFields(form)
      }
    }
    // onFormSubmit() {
    //   this.dialogRef.close(this.customSettingsForm.value);
    // }
}
