import { Component, ContentChild, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'app/shared/models/DialogData';

@Component({
  selector: 'ms-select-member',
  templateUrl: './select-member.component.html',
  styleUrls: ['./select-member.component.scss']
})
export class SelectMemberComponent implements OnInit {
  selectedMember:any;
  constructor(public dialogRef: MatDialogRef<SelectMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {name:string, no: boolean, memberList: any, isSpouseAvailable: boolean}) { }

  ngOnInit() {
    this.data.isSpouseAvailable = this.data.memberList.length > 0;
  }
  
  onNoClick():void {
    this.dialogRef.close();
  }

}
