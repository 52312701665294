import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { validateAllFormFields } from 'app/shared/utils/common-functions';

@Component({
	selector: 'ms-edit-new-user',
	templateUrl: './edit-new-user.component.html',
	styleUrls: ['./edit-new-user.component.scss']
})
export class EditNewUserComponent implements OnInit {

	form: FormGroup
	data: any;
	editTitle: any;

	constructor(public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<EditNewUserComponent>) { }

	ngOnInit() {
		this.form = this.formBuilder.group({
			name: [null, [Validators.required]],
			description: [],
			// order: [null, Validators.required]
		});

		if (this.data) {
			this.form.patchValue({
				name: this.data.name,
				description: this.data.description,
				// order: this.data.order
			});
		}
	}

	onFormSubmit(form) {
		if (form.valid) {
			this.dialogRef.close(form.value);
		} else {
			validateAllFormFields(form)
		}
	}
}
