import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllRelationshipList(): Observable<any> {
    return this.http.get<any>(this.authService._getAllRelationship);
  }

  public getRelationshipbyId(Id): Observable<any> {
    return this.http.get<any>(this.authService._getRelationshipbyId + '?relationshipId=' + Id);
  }

  public addRelationship(data) {
    return this.http.post(this.authService._addRelationship, data);
  }

  public updateRelationshipDetail(data) {
    return this.http.put(this.authService._updateRelationship, data);
  }

  public relationshipUpdateSequence(data) {
    return this.http.put(this.authService._relationshipUpdateSequence, data);
  }

  public deleteRelationship(deleteId): Observable<any> {
    return this.http.delete(this.authService._deleteRelationship + '?id=' + deleteId);
  }

  public deactivateRelationship(deactivateId): Observable<any> {
    return this.http.put(this.authService._deactivateRelationshipById + '?id=' + deactivateId, '');
  }

  public ActiveRelationshipById(Id) {
    return this.http.put(this.authService._activeRelationshipById + '?id=' + Id, '');
  }
}
